package service.tabs_api

import com.raquo.airstream.core.EventStream
import com.raquo.laminar.api.L._
import common.ServiceType.ServiceType
import common.airstream_ops.AirStreamOps
import common.microsoft_cards.{MsMessage, MsMessageContent}
import common.{AddinPage, AddinSection, AurinkoAccount, AurinkoApiPage, AurinkoUser, CirceStringOps, GraphApiResponse, MsChannel, MsTeam, RepfabricManufacturer, RepfabricUser, RfApiPage, ServiceType, StorageMapping, TabCompanyOps, TokenData}
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax.EncoderOps
import io.circe.{Decoder, Encoder, parser}
import org.scalajs.dom
import root_pages.sign_in.SfLoginType
import root_pages.sign_in.SfLoginType.SfLoginType
import service.ServiceManager
import service.exceptions.{AurinkoApiException, NotAuthorizedException, XhrFailedException}
import service.http.ApiRequester
import service.origins.Origins
import service.tabs_api.TabCompany.TabCompany
import service.tabs_api.model.{SubEntityData, SubEntityDataType}
import service.tabs_api.model.SubEntityData.Preview
import service.ui_logging.UILogger
import wvlet.log.Logger

import java.time.Instant
import scala.concurrent.Promise
import scala.scalajs.js
import scala.scalajs.js.URIUtils
import scala.scalajs.js.annotation.JSGlobal
import scala.util.Try

trait JsVarargsFn extends js.Function {
  def apply(args: Any*): Unit
}

@js.native
@JSGlobal
object microsoftTeams extends js.Object {
  def getContext(func: js.Function1[MsContext, Unit]): Unit = js.native

  def shareDeepLink(obj: js.Object): Unit = js.native

  val settings: MsTeamsSettings = js.native

  val authentication: MsAuthentication = js.native

  val conversations: MsConversations = js.native
}

//@js.native
//trait DeepLinkParameters extends js.Object {
//  val subEntityId: String = js.native
//}

@js.native
trait MsContext extends js.Object {
  val groupId: js.UndefOr[String] = js.native
  val channelId: js.UndefOr[String] = js.native
  val channelType: js.UndefOr[String] = js.native
  val userPrincipalName: js.UndefOr[String] = js.native
  val entityId: js.UndefOr[String] = js.native
  val subEntityId: js.UndefOr[String] = js.native
  val tid: js.UndefOr[String] = js.native
  val hostClientType: js.UndefOr[String] = js.native
}

@js.native
trait MsAuthentication extends js.Object {
  def authenticate(obj: js.Object): Unit = js.native
}

@js.native
trait ConversationResponse extends js.Object {
  val conversationId: js.UndefOr[String] = js.native
}


@js.native
trait MsRemoveEvent extends js.Object {
  def notifySuccess(): Unit = js.native
}

@js.native
trait MsTeamsSettings extends js.Object {
  def registerOnRemoveHandler(func: js.Function1[MsRemoveEvent, Unit]): Unit = js.native
}

@js.native
trait MsConversations extends js.Object {
  def openConversation(obj: js.Object): Unit = js.native
}

//microsoftTeams.conversations.openConversation();

case class TabsApi(apiUrl: String) {

  //todo: rework!
  private val tabOwnerCompany: Var[Option[TabCompany]] = Var(None)

  def needToDefineTabOwner: Boolean = tabOwnerCompany.now.isEmpty

  def tabCompany: TabCompany = tabOwnerCompany.now.getOrElse(throw new Exception("Missing tab owner type "))
  val $tabOwner: Signal[Option[TabCompany]] = tabOwnerCompany.signal

  def setTabCompany(company: String): Unit = tabOwnerCompany
    .set(Some(
      Try(TabCompany.withName(company)).toOption.getOrElse(TabCompany.Yoxel)
    ))

  def crmType: Option[ServiceType] = tabCompany.toServiceType.orElse(msSubEntityDetails.map(_.serviceType))

  var msGroupId: Option[String] = None
  var msChannelId: Option[String] = None
  var msUserName: Option[String] = None
  var msTenantId: Option[String] = None
  var msEntityId: Option[String] = None
  var msSubEntityDetails: Option[SubEntityDetails] = None
  var msConvoId: Option[String] = None
  var msHostClientType: Option[String] = None


  def groupId: String = msGroupId.getOrElse(throw new Exception("Missing ms groupId"))

  def channelId: String = msChannelId.getOrElse(throw new Exception("Missing ms channelId"))

  def userName: String = msUserName.getOrElse(throw new Exception("Missing ms channelId"))

  def tenantId: String = msTenantId.getOrElse(throw new Exception("Missing ms tenantId"))

  def subEntityDetails: SubEntityDetails = msSubEntityDetails.getOrElse(throw new Exception("Missing ms SubEntityId"))

  def convoId: String = msConvoId.getOrElse(throw new Exception("Missing ms SubEntityId"))

  def hostClientType: String = msHostClientType.getOrElse(throw new Exception("Missing ms hostClientType"))

  def isMobile: Boolean = List("android", "ios", "ipados").contains(hostClientType.toLowerCase)


  //  def groupId: String = msGroupId.getOrElse(throw new Exception("Missing ms groupId"))
  //  def channelId: String = msChannelId.getOrElse(throw new Exception("Missing ms channelId"))

  private val log = Logger.of[TabsApi]
  private val uiLogger = UILogger("TabsApi", ServiceManager.UILogging)

  uiLogger.info("TabsApi initialized")

  //  def setMsContextIds(msCont: MsContext): Unit = {
  //    groupId = msCont.groupId
  //    channelId = Some(msCont.channelId)
  //  }
  private val apiRequester: ApiRequester = ApiRequester(apiUrl, validateResponse)

  private var clientId: Option[String] = None

  def auClientId: String = clientId.getOrElse(throw new Exception("Aurinko client id is not defined"))

  def needClientId: Boolean = clientId.isEmpty

  def setClientId(str: String): Unit = clientId = Some(str)

  var isPrivateChannel: Boolean = false

  def missingMsContext: Boolean = msChannelId.isEmpty


  //  def getClientId(clientId: Option[String] = None): String = {
  //    if (clientIdValue.isEmpty) clientIdValue = clientId.getOrElse(throw new Exception("Client ID isn't saved and required in query parameter in URI"))
  //    println(clientIdValue)
  //    clientIdValue
  //  }

  val standardApiPageSize = 30
  val embeddedApiPageSize = 15

  def openConvo(
                 subEntityId: Option[String] = None,
                 title: Option[String] = None,
                 conversationId: Option[String] = None
                 ): EventStream[Unit] = {
    log.info(s"opening conversation ${conversationId.getOrElse("")}")

    val p = Promise[Unit]

    val stream = EventStream.fromFuture(p.future)

    val sId = subEntityId.getOrElse("subentity_id")// this field is required, but there is no restrictions to its' value
  val cId = conversationId.getOrElse("")
    val t  = title.getOrElse("")

    try {

      microsoftTeams.conversations.openConversation(
        js.Dynamic.literal(
          "subEntityId" -> sId,
          "entityId" -> msEntityId.getOrElse(throw new Exception("empty entityId")),
          "channelId" -> msChannelId.getOrElse(throw new Exception("empty channelId")),
          "title" -> t,
          "conversationId" -> cId,
          "onStartConversation" -> ((resp: ConversationResponse) => {
            log.info(s"On start conversation response ID is ${resp.conversationId}")
          }),
          "onCloseConversation" -> ((resp: ConversationResponse) => {
            log.info(s"On close conversation response ID is ${resp.conversationId}")
          })
        ))
      uiLogger.fine("openConversation")
      p.success(())
    } catch {

      case e: Throwable =>
        uiLogger.error("Failed to open conversation")
        p.failure(e)
//        throw new Exception(e.toString)
    }
    stream
  }

  def setMsContextIds(): EventStream[Unit] = {

    log.info("setMsContextIds")
    uiLogger.info("setcMscContextIds")
    val p = Promise[Unit]

    val stream = EventStream.fromFuture(p.future)

    try {
      microsoftTeams.getContext((context: MsContext) => {
        log.info("Ms getContext callback")
//        uiLogger.info("MsContext callback")

        msGroupId = context.groupId.toOption.filterNot(_.isEmpty)
//        uiLogger.info(s"msGroupId ${context.groupId}")

        msChannelId = context.channelId.toClearOption

        isPrivateChannel = context.channelType.contains("Private")

        msUserName = context.userPrincipalName.toClearOption

        msTenantId = context.tid.toClearOption

        msEntityId = context.entityId.toClearOption

        msHostClientType = context.hostClientType.toClearOption

        uiLogger.info(s"groupId: $msGroupId channelId: $msChannelId isPrivateChannel: $isPrivateChannel msTenantId: $msTenantId  hostClientType: $msHostClientType")
//        log.info(s"groupId: $msGroupId channelId: $msChannelId isPrivateChannel: $isPrivateChannel msTenantId: $msTenantId  hostClientType: $msHostClientType")


        val subEnt = context.subEntityId.toClearOption

        uiLogger.info(s"subEntityId nm $subEnt")

//liza's mock:
//        msSubEntityDetails = Some(SubEntityDetails(
//          sfOrgId = "00D5f000005WnTDEA0",
//          objId = "0065f000005WjvhAAC",
//          objType = "Opportunity",
//          objRtId = None
//        ))
//        msConvoId = Some("1659029973889")
//

         if(subEnt.exists(_.nonEmpty)) {
           log.info(s"subEntityId $subEnt")
           uiLogger.info(s"SubEntityId not empty $subEnt")
          parser.decode[SubEntityData](subEnt.get) match {
            case Left(value) =>
              log.warn("Looks like subEntity is old format, fallback to old parser. Err: " + value)
              try {
                msSubEntityDetails = Some(SubEntityDetails(subEnt.get))
                msConvoId = Some(subEnt.get.split("\\.").last)
              } catch {
                case _: Throwable => throw new Exception("Unexpected format of subentity id")
              }
            case Right(p: SubEntityData.Preview) =>

              log.info(s"SubEntityData: ${p.asJson.noSpaces}")
              msSubEntityDetails = Some(SubEntityDetails(
                sfOrgId = p.sfOrgId.trim,
                objId = p.objId.trim,
                objType = p.objType.trim,
                objRtId = p.recordTypeId.map(_.trim),
                serviceType = p.serviceType.getOrElse(ServiceType.salesforce)
              ))
              msConvoId = p.messageId
            case Right(s: SubEntityData.Search) =>
              log.warn("Search is not supported yet.")
              throw new Exception ("Search is not supported yet.")
            case Right(l: SubEntityData.Login) =>
              log.info("Login")
              msConvoId = l.messageId
          }
        }
//        log.info(s"groupId: $msGroupId channelId: $msChannelId isPrivateChannel: $isPrivateChannel msTenantId: $msTenantId msSubentityId: $msSubEntityDetails hostClientType: $msHostClientType")
        p.success(())
      })
    } catch {
      case e: Throwable => p.failure(new Exception(e.toString))
    }

    stream
  }

  implicit class MsContextValuesOpps(value: js.UndefOr[String]) {
    def toClearOption: Option[String] = value.toOption
      .filterNot(_.isEmpty)
      .filterNot(_ == null)
      .filterNot(_.equalsIgnoreCase("null"))
  }

  def authUrl(authType: Option[ServiceType], sfLoginType: Option[SfLoginType]): String = {

    val url = if (authType.nonEmpty) s"$apiUrl/auth/authorize" else s"${Origins.apiOrigin}/auth/crm_select"
    val clId = s"clientId=$auClientId"
    val svcType = authType.map(t => s"&serviceType=${t.value}").getOrElse("")
    val accType = s"&userAccount=${if (authType.contains(ServiceType.office365)) "primary" else "secondary"}"
    val nativeScopes = if (authType.contains(ServiceType.office365))  {
      s"""&nativeScopes=${
        URIUtils.encodeURIComponent("ChannelMessage.Send Team.ReadBasic.All Channel.ReadBasic.All")

      }"""
    } else ""

    val loginHint = if (authType.contains(ServiceType.office365)) {
      s"&loginHint=${URIUtils.encodeURIComponent(s"${msUserName.getOrElse(throw new Exception("Missing microsoft userPrincipalName"))}")}"
    } else ""

    val prompt = "&prompt=select_account"

    val authServiceType =
      if (authType.contains(ServiceType.office365)) "&authServiceType=MsTeamsBot"
      else ""

    val sandbox =
      if (sfLoginType.contains(SfLoginType.Sandbox)) "&sandbox=true"
      else ""

    val returnUrl = s"&returnUrl=${URIUtils.encodeURIComponent(s"${dom.window.location.origin.getOrElse(throw new Exception("Can't get a window location"))}/msteams_auth_callback.html")}"

    s"$url?$clId$svcType$accType$nativeScopes$loginHint$prompt$authServiceType$sandbox$returnUrl"
  }

  def getMicrosoftAppId: EventStream[String] = apiRequester
    .get(s"/bot/proxy/teamsbotApp/MsTeamsBot/appId?orgId=$tenantId", headers = Map("X-Aurinko-ClientId" -> auClientId))
    .map(_.decodeAs[Map[String, String]])
    .map(_.getOrElse("appId", throw new Exception("Failed to get ms app id")))

  def postMessageToConvo(content: MsMessageContent, convoId: String, primaryAccId: Int): EventStream[String] = apiRequester
    .post(s"/direct/teams/$groupId/channels/$channelId/messages/$convoId/replies",
      body = Some(content.toJson),
      headers = auHeaders(primaryAccId)
    )

  def postMessageToChannel(content: MsMessageContent, primaryAccId: Int): EventStream[MsMessage] = apiRequester
    .post(s"/direct/teams/$groupId/channels/$channelId/messages",
      body = Some(content.toJson),
      headers = auHeaders(primaryAccId)
    )
    .map(_.decodeAs[MsMessage])

  def updateChannelMessage(content: MsMessageContent, primaryAccId: Int, messageId: String): EventStream[MsMessage] = apiRequester
    .patch(s"/direct/teams/$groupId/channels/$channelId/messages/$messageId",
      body = Some(content.toJson),
      headers = auHeaders(primaryAccId)
    )
    .map(_.decodeAs[MsMessage])

  def aurinkoUser(): EventStream[AurinkoUser] = apiRequester
    .get("/user", headers = Map("X-Aurinko-ClientId" -> auClientId))
    .map(_.decodeAs[AurinkoUser])

  def refreshAurinkoUser(userId: String): EventStream[Unit] = apiRequester
    .post(s"/bot/proxy/teamsbotApp/user/$userId/refresh", headers = Map("X-Aurinko-ClientId" -> auClientId))
    .mapTo(())


  def logout: EventStream[Unit] = apiRequester
    .post(
      "/user/logout",
      headers = Map("X-Aurinko-ClientId" -> auClientId)
    )
    .map(_ => ())

  def logoutAccount(accId: Int): EventStream[Unit] = apiRequester
    .delete(path = s"/user/accounts/$accId", headers = Map("X-Aurinko-ClientId" -> auClientId)).map(_ => ())

  def getAddinObject(`type`: String, objId: String, secondaryAccId: Int, objRtId: Option[String]): EventStream[AddinPage] = apiRequester
    .get(s"/addin/objects/${`type`}/$objId",
      queryParams = Map("rtId" -> objRtId),
      headers = auHeaders(secondaryAccId))
    .map(_.decodeAs[AddinPage])

  def getAddinLoadPath(endpointLink: String, secondaryAccId: Int): EventStream[List[AddinSection]] = apiRequester
    .get(s"/addin$endpointLink", headers = auHeaders(secondaryAccId))
      .map(_.decodeAs[List[AddinSection]])

  def loadAddinSection(path: String, secondaryAccId: Int): EventStream[AddinSection] = apiRequester
    .get(s"/addin$path",
      headers = auHeaders(secondaryAccId)
    )
    .map(_.decodeAs[AddinSection])

  def accounts: EventStream[List[AurinkoAccount]] = apiRequester
    .get(path = "/user/accounts", headers = Map("X-Aurinko-ClientId" -> auClientId))
    .map(_.decodeAs[AurinkoApiPage[AurinkoAccount]])
    .map(_.records)

  def getGroupIdFromStorage(primaryAccId: Int): EventStream[Option[String]] = apiRequester.get(s"/storage/user/channel-team:$channelId",
    headers = auHeaders(primaryAccId)).map(_.decodeAs[Map[String, String]].get(s"channel-team:$channelId"))

  def saveChannelTeamId(primaryAccId: Int, tId: String): EventStream[Unit] = apiRequester.put(s"/storage/user/channel-team:$channelId",
    headers = auHeaders(primaryAccId), body = Some(Map("value" -> tId).asJson)
  ).mapTo(())
  //graph

  def getUserTeams(primaryAccId: Int): EventStream[List[MsTeam]] = apiRequester
    .get("/direct/me/joinedTeams?\\$select=id&\\$filter=" + URIUtils.encodeURIComponent("isArchived eq false"),
      headers = auHeaders(primaryAccId))
    .map(_.decodeAs[GraphApiResponse[List[MsTeam]]])

//    .recoverToTry.collect {
//    case Failure(e)  if e.isInstanceOf[NotAuthorizedException] => throw new Exception
//
//    case Success(t) => Some(t)
//  }
//    .collect { case Some(v) => v.value }
    .map(_.value)

  def getTeamPrivateChannels(teamId: String, primaryAccId: Int): EventStream[List[MsChannel]] = apiRequester
    .get(s"/direct/teams/$teamId/channels",
      headers = auHeaders(primaryAccId),
      queryParams = Map(
        "$select" -> Some("id"),
        "$filter" -> Some("membershipType eq 'private'"))
    )
    .map(_.decodeAs[GraphApiResponse[List[MsChannel]]])
//    .recoverToTry.collect {
//    case Failure(e) => log.warn(e)
//      None
//    case Success(t) => Some(t)
//  }
    .map { _.value }

  //repfabric:

  def me(secondaryAccId: Int): EventStream[RepfabricUser] = apiRequester
    .get("/direct/users/me", headers = auHeaders(secondaryAccId))
    .map(_.decodeAs[RepfabricUser])

  def decodeChannelId(tenantId: String, channelId: String, teamId: String): EventStream[String] = apiRequester
    .get(
      s"/bot/proxy/teamsbotApp/MsTeamsBot/$tenantId/channels/${URIUtils.encodeURIComponent(s"$teamId/$channelId")}/encoded",
      headers = Map("X-Aurinko-ClientId" -> auClientId))
    .map(_.decodeAs[Map[String, String]])
    .map(_.getOrElse("value", throw new Exception("Unexpected data for encoded channelId")))

  def generateToken(secondaryAccId: Int, data: TokenData): EventStream[String] = apiRequester
    .post(
      "/subscriptions/token/generate",
      headers = auHeaders(secondaryAccId),
      body = Some(data.asJson))
    .map(_.decodeAs[Map[String, String]])
    .map(_.getOrElse("token", throw new Exception("Unexpected format of token")))

  def rfManufacturers(secondaryAccountId: Int): EventStream[List[RepfabricManufacturer]] = rfFullManufacturers(Nil, None, secondaryAccountId)

  private def rfFullManufacturers(init: List[RepfabricManufacturer] = Nil, nextPageId: Option[String] = None, secondaryAccountId: Int): EventStream[List[RepfabricManufacturer]] =
    rfManufacturersPage(nextPageId, secondaryAccountId).flatMap {
      case resp if resp.nextPageId.isDefined => rfFullManufacturers(init ++ resp.records, resp.nextPageId, secondaryAccountId)
      case resp => (init ++ resp.records).streamed
    }

  //
  private def rfManufacturersPage(nextPageId: Option[String] = None, secondaryAccountId: Int): EventStream[RfApiPage[RepfabricManufacturer]] = apiRequester
    .get("/direct/companies", queryParams = Map("type" -> Some("1"), "next-page-id" -> nextPageId), headers = auHeaders(secondaryAccountId))
    .map(_.decodeAs[RfApiPage[RepfabricManufacturer]])

  def channelManufacturerId(clientOrgId: String, primaryAccId: Int): EventStream[Option[String]] = apiRequester
    .get(
      s"/storage/organization/${URIUtils.encodeURIComponent(rfMappingStorageKey(clientOrgId))}",
      headers = auHeaders(primaryAccId))
    .map(_.decodeAs[Map[String, String]].get(rfMappingStorageKey(clientOrgId)) match {
    case Some(v) if v.nonEmpty =>
      log.info(s"id: $v")
      v.split("_").headOption
    case _ => None
  })

  def updateChannelManufacturerId(clientOrgId: String, primaryAccId: Int, mId: String): EventStream[String] = apiRequester.put(
    s"/storage/organization/${URIUtils.encodeURIComponent(rfMappingStorageKey(clientOrgId))}",
    headers = auHeaders(primaryAccId),
    body = Some(StorageMapping(Some(s"$mId${if (isPrivateChannel) s"_$primaryAccId" else ""}")).asJson))

  def deleteChannelManufacturer(clientOrgId: String, accId: Int): EventStream[Unit] = apiRequester
    .delete(s"/storage/organization/${URIUtils.encodeURIComponent(rfMappingStorageKey(clientOrgId))}", headers = auHeaders(accId))
    .mapTo(())

  def saveTokenToStorage(token: String, primaryAccId: Int): EventStream[Unit] = apiRequester.put(
    s"/storage/organization/$token",
    headers = auHeaders(primaryAccId),
    body = Some(StorageMapping(Some(s"${groupId}_$channelId")).asJson))
    .mapTo(())


  def getAppIdFromStorage(primaryAccId: Int): EventStream[Option[String]] = apiRequester.get(s"/storage/organization/appId",
    headers = auHeaders(primaryAccId)).map(_.decodeAs[StorageMapping].value)

  def saveAppIdToStorage(appId: String, primaryAccId: Int): EventStream[Unit] = apiRequester.put(
    s"/storage/organization/appId",
    headers = auHeaders(primaryAccId),
    body = Some(StorageMapping(Some(s"$appId")).asJson))
    .mapTo(())



  //  def calendars(accId: Int): EventStream[List[Calendar]] = apiRequester.repeatGet("/calendars", headers = auHeaders(accId))
  //
  //  def graphEvents(accId: Int) = apiRequester
  //    .get(s"/direct/groups/$groupId/events", headers = auHeaders(accId))
  //
  //  def graphCalendarEvents(accId: Int) = apiRequester
  //    .get(s"/direct/groups/$groupId/calendar/events", headers = auHeaders(accId))
  //
  //  def graphGroups(accId: Int) = apiRequester
  //    .get(s"/direct/groups", headers = auHeaders(accId), queryParams = Map("$select" -> Some("id,resourceProvisioningOptions,displayName")))
  //
  //  def graphGroup(accId: Int, groupId: String) = apiRequester
  //    .get(s"/direct/groups/$groupId", headers = auHeaders(accId))


  //  def channelCalendarId(clientOrgId: String, accId: Int): EventStream[Option[String]] = apiRequester
  //    .get(s"/storage/organization/${orgKey(clientOrgId)}", headers = auHeaders(accId)).map(_.decodeAs[StorageMapping].value)
  //
  //  def updateChannelCalendarId(clientOrgId: String, accId: Int, calId: String): EventStream[String] = apiRequester.put(
  //    s"/storage/organization/${orgKey(clientOrgId)}",
  //      headers = auHeaders(accId),
  //      body = Some(StorageMapping(Some(calId)).asJson))
  //

  def auHeaders(accId: Int) = Map("X-Aurinko-AccountId" -> accId.toString, "X-Aurinko-ClientId" -> auClientId)

  def rfMappingStorageKey(clientOrgId: String): String = {
    log.info("mapping-rf/" + groupId + "/" + channelId + "/" + clientOrgId)
    "mapping-rf/" + groupId + "/" + channelId + "/" + clientOrgId
  }

  //implicit val ldtDecoder: Decoder[Instant] = Decoder[Long].map(Instant.ofEpochMilli)
  //implicit val ldtEncoder: Encoder[Instant] = Encoder[Long].contramap(_.toEpochMilli)
  implicit val ldtDecoder: Decoder[Instant] = Decoder[String].map(Instant.parse)
  implicit val ldtEncoder: Encoder[Instant] = Encoder[String].contramap(_.toString)

  def validateResponse(resp: dom.XMLHttpRequest): String = resp.status match {
    case c if c >= 200 && c < 300 => resp.responseText
    case c if c == 401 => throw NotAuthorizedException()
    case c if c == 0 => throw XhrFailedException()
    case _ =>
      log.info(resp.status)
      throw parser.decode[AurinkoApiException](resp.responseText).fold(
        _ => new Exception(resp.responseText),
        v => v
      )
  }


  /*
  @js.native
  @JSGlobalScope
  object msContext extends js.Object {
    var groupId: String = js.native
    var channelId: String = js.native
  }

  val x = msContext.groupId
  val y = msContext.channelId*/
}


case class SubEntityDetails(
                             sfOrgId: String,
                             objType: String,
                             objId: String,
                             objRtId: Option[String],
                             serviceType: ServiceType
                           ) {
  def toContextString(convoId: Option[String]): String = Preview(
    sfOrgId,
    objType,
    objId,
    convoId,
    SubEntityDataType.preview,
    objRtId,
    Some(serviceType)
  )
    .asJson.deepDropNullValues.noSpaces


  override def toString: String = s" sfOrgId: $sfOrgId; objType: $objType; objId: $objId; objRecordTypeId: $objRtId"
}

object SubEntityDetails{
  def apply(contextString: String): SubEntityDetails = contextString.decodeAs[SubEntityDetails]


}

object TabCompany extends Enumeration {
//  case class Val(value: String) extends super.Val(value) {
//    def toServiceType
//  }

  type TabCompany = Value
  val Repfabric, Yoxel = Value
}

