package service.origins

import org.scalajs.dom

object Origins {

	val wlo: String = dom.window.location.origin.getOrElse(throw new Exception("Failed to get window.location.origin"))
	val apiOrigin: String = wlo match {
		case str if str.startsWith("file://") || dom.window.location.host.contains("ngrok.io") => "http://localhost:9000"
		case _ => wlo
	}

	val apiUrl = s"$apiOrigin/v1"

}
