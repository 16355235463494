package common

import common.ServiceType.ServiceType
import io.circe.{Decoder, Encoder, Json}
import io.circe.generic.auto.exportEncoder
import io.circe.syntax.EncoderOps
import service.tabs_api.SubEntityDetails

import scala.scalajs.js.URIUtils

package object microsoft_cards {

	case class MsMessageContent(
	                             card: AdaptiveCard,
	                             subject: String = "",
	                             message: String = ""
	                           ) {

		private val guid = java.util.UUID.randomUUID().toString

		val content = s"""$message<attachment id="$guid"></attachment>"""

		val body: Map[String, Option[String]] = Map("contentType" -> Some("html"), "content" -> Some(content))

		def toJson: Json = Map(
			"body" -> body.asJson,
			"subject" -> subject.asJson,
			"attachments" -> List(MessageAdaptiveCardAttachments( id = guid,
																														content = card.asJson.deepDropNullValues.toString
														)).asJson.deepDropNullValues
		).asJson
	}

	case class MessageAdaptiveCardAttachments(
	                             id: String,
	                             contentType: String = "application/vnd.microsoft.card.adaptive",
	                             content: String,
	                             thumbnailUrl: Option[String] = None,
	                             name: Option[String] = None,
	                             contentUrl: Option[String] = None,

	                             )

//	case class AdaptiveCardContent(contentType: String = "application/vnd.microsoft.card.adaptive", content: AdaptiveCard)

	case class AdaptiveCard(
		                       `type`: String = "AdaptiveCard",
		                       body: List[AdaptiveCardElement],
		                       version: String = "1.5",
		                       id: Option[String] = None,
//		                       refresh: Option[AdaptiveRefreshComponent] = None,
//		                       msteams: Option[MsTeamsElements] = None,
		                       actions: List[AdaptiveActionOpenUrl] = List.empty
	                       )

	sealed trait AdaptiveCardElement

	object AdaptiveCardElement {
		implicit val encodeData: Encoder[AdaptiveCardElement] = Encoder.instance {
			case textMessage @ AdaptiveTextBlock(_, _, _, _, _, _, _, _) => textMessage.asJson
			case factsSet @ FactSet(_, _) => factsSet.asJson
		}
	}

	case class AdaptiveTextBlock(
		                            text: String,
		                            size: Option[String] = None,
		                            weight: Option[String] = None,
		                            color: Option[String] = None,
		                            horizontalAlignment: Option[String] = None,
		                            spacing: Option[String] = None,
		                            `type`: String = "TextBlock",
		                            wrap: Boolean = true
	                            ) extends AdaptiveCardElement

	case class FactSet(
		                   `type`: String = "FactSet",
		                   facts: List[Fact]
	                   ) extends AdaptiveCardElement

	case class Fact(
		               title: String,
		               value: String
	               )

	case class AdaptiveActionOpenUrl(url: String,
	                                 title: Option[String],
	                                 `type`: String = "Action.OpenUrl"
	                                )

	case class MsMessage(
	                    id: String
	                    )

	def generateDeepLink(objId: String,
	                     objType: String,
	                     appId: String,
	                     sfOrgId: String,
	                     convoId: String,
	                     channelId: String,
	                     objRtId: Option[String],
	                     serviceType: ServiceType
	                    ): String = {

		val objectSubEntityDetails = SubEntityDetails(
			sfOrgId = sfOrgId,
			objId = objId,
			objType = objType,
			objRtId = objRtId,
			serviceType = serviceType
		)

		val encodedContext =
			URIUtils.encodeURIComponent(
				s"""{"subEntityId": "${objectSubEntityDetails.toContextString(Some(convoId))}", "channelId": "${channelId}"}"""
			)

		s"https://teams.microsoft.com/l/entity/$appId/yxl_sync_${URIUtils.encodeURIComponent(channelId)}?context=$encodedContext"


	}

	def objectAdaptiveCard(objName: String,
//	                       objId: String,
	                       objType: String,
//	                       appId: String,
	                       label: String,
	                       objCRMLink: String,
//	                       channelId: String,
//	                       convoId: Option[String],
	                       deepLink: Option[String] = None
	                      ): AdaptiveCard = {

//		val objCRMLink = s"${accounts.secondaryAccount.serverUrl.getOrElse("missing serverurl")}/$objId"

		val shareMessage = AdaptiveTextBlock(text =
			s"$objType [[View in Salesforce]]($objCRMLink) \n $objName ${if (label.nonEmpty) "\n" + label else ""}")

//		val deepLink = convoId.map(cId => generateDeepLink(objId, objType, appId, cId))

		val action = deepLink.map(l => AdaptiveActionOpenUrl(title = Some("Preview"), url = l))

		AdaptiveCard(body = shareMessage :: Nil, actions = action.toList)
	}



}
