package common.auto_suggest

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Menu, Textfield}
import com.raquo.laminar.api.L._
import common.MountContextOpps
import common.airstream_ops.AirStreamOps
import org.scalajs.dom
import org.scalajs.dom.html
import wvlet.log.Logger

case class AutoSuggestComponent[T](
                                    valueVar: Var[Option[T]],
                                    $availableValues: Signal[List[T]],
                                    label: String,
                                    $disabled: Signal[Boolean] = false.signaled,
                                    inputCls: Option[String] = None,
//                                    $showFormErrors: Signal[Boolean],
                                    itemsToShowWithEmptySearch: Signal[List[T]] = Nil.signaled,
                                    strict: Boolean = true, // the value must be one of the availableValues
                                    charactersNeededForSearch: Int = 2,
                                    initialValidation: Boolean = true,
                                    required: Boolean = false,
                                    menuMaxHeight: Option[String] = None
                               ) {
  private val log = Logger.of[AutoSuggestComponent[T]]

  private val showMenu: Var[Boolean] = Var(false)

  private val inputString: Var[String] = Var(if (valueVar.now.isDefined) valueVar.now.get.toString else "")

  private var changed = false

  private val shownList: Var[List[T]] = Var(Nil)
//  setItemsToShow(inputString.now)
//  inputBus.emit(inputString.now)

  private val inputClickBus = new EventBus[Unit]

  private val hasError: Var[Boolean] = Var(false)
//  private val inputBus: EventBus[String] = new EventBus[String]

  private val textInput = Textfield(
    _ => cls := (if(inputCls.isDefined) inputCls.get else "input-width--medium"),
    _.label := label,
    _.value := inputString.now,
    _.value <-- valueVar.signal.changes.filter(_.nonEmpty).map(_.get.toString),//!
    _ => onInput.mapToValue --> Observer[String](onNext = s => {

      changed = true
      inputString.set(s)
      showMenu.set(true)
    }),

    _ => inputString.signal.combineWith($availableValues) --> Observer[(String, List[T])](onNext = {
      case (str, values) =>
        log.info(s"input string signal $str values $values valueVar ${valueVar.now}")
//        inputString.set(str)
//        log.info(s"$str, values: ${values.map(_.toString).mkString(", ")}")

        if(strict) {
          val newVal = values.find(_.toString.toLowerCase == str.toLowerCase)
//          log.info(s"new value: $newVal")
          valueVar.set(newVal)
          hasError.set(
            if (!required) newVal.isEmpty && str.nonEmpty && (initialValidation || changed)
            else newVal.isEmpty && (initialValidation || changed)
          )

        } else {
          valueVar.set(Some(str.asInstanceOf[T]))
          hasError.set(!required || str.trim.nonEmpty)
        }

        setItemsToShow(str, values)

    }),
    _.helper <-- hasError.signal.map {
      case false => " "
      case true => s"Please enter valid ${label.toLowerCase()}"
    },
    _.helperPersistent := true,
    _ => cls <-- hasError.signal.map { case true => "with-error" case false => "" },
    _.disabled <-- $disabled,
    _ => onClick.mapTo(()) --> inputClickBus,
    _ => inputClickBus.events
      .withCurrentValueOf($disabled)
      .filter(_ == false)
      .mapTo(true) --> showMenu,

    _.iconTrailing := (if(strict) "search" else "")
  )

//  private def handleInput(string: String, values: List[T]): Unit = {
//    inputString.set(string)
//
//    if(strict) {
//      val newVal = values.find(_.toString.toLowerCase == string.toLowerCase)
//      valueVar.set(newVal)
//      hasError.set(newVal.isEmpty)
//    } else {
//      valueVar.set(Some(string.asInstanceOf[T]))
//      hasError.set(false)
//    }
//
//    setItemsToShow(string, values)
//    showMenu.set(true)
//  }


  private def sortValues(string: String, values: List[T]): List[T] =
      values.filter(_.toString.toLowerCase.startsWith(string.toLowerCase)).sortBy(_.toString) ++
        values.filterNot(i => i.toString.toLowerCase.startsWith(string.toLowerCase))

  private def setItemsToShow(string: String, values: List[T]): Unit = shownList.set(
    if(string.length >= charactersNeededForSearch) {
      sortValues(string, values.filter(_.toString.toLowerCase.contains(string.toLowerCase)))
    } else Nil
  )

  val node: Div = div(

    //initial validation for strict mode
//    if(strict) {
    //      $availableValues.map(_.toString.toLowerCase).map(list => valueVar.now.isDefined && !list.contains(valueVar.now.get.toString.toLowerCase)) --> hasError
    //    } else None,
    //
    //    $showFormErrors.changes.filter(_ && (strict || required))
    //      .sample($availableValues)
    //      .map{l => {
    //        log.info(s"${valueVar.now} ${inputString.now}")
    //        valueVar.now.isEmpty && (required || inputString.now.nonEmpty)
    //      }} --> hasError,


    cls := "slds-is-relative input-width--medium",

    //    $availableValues.map(_.map(_.toString).mkString(", ")) --> Observer[String](onNext = str => log.info(str)),

    textInput,
    Menu(
      _ => cls := "width-medium height-medium",
      _.open <-- showMenu.signal.combineWith(shownList.signal).map {
        case (show, items) if show && (strict || items.nonEmpty) => true
        case _ => false
      },
      _.onOpened --> Observer[dom.Event](onNext = _ => textInput.ref.focus),
      _.onClosed.mapTo(false) --> showMenu,
      _.absolute := true,
      _.y := 28,
      _.x := 0,
      _ => child.maybe <-- shownList.signal.map {
        case Nil if strict => Some(small(
          cls := "slds-show slds-m-vertical--small grey",
          paddingLeft := "var(--mdc-list-side-padding, 16px)",
          paddingRight := "var(--mdc-list-side-padding, 16px)",
          child.text <-- inputString.signal.map {
            case s if s.length < charactersNeededForSearch => s"Type $charactersNeededForSearch or more characters to search ${label.toLowerCase}"
            case _ => "No items found"
          }
        ))
        case _ => None
      },
      _ => div(
        cls := "input-width--medium",
        menuMaxHeight.map(h => maxHeight := h),
        children <-- shownList.signal.flatMap {
          case Nil => itemsToShowWithEmptySearch
          case l => l.signaled
        }.split(_.toString)((_: String, _: T, $value: Signal[T]) => {
          val clickBus = new EventBus[dom.MouseEvent]
          ListItem(
            _.disabled <-- $disabled,
            _.slots.default(span(child.text <-- $value.map(_.toString))),
            _ => onClick --> clickBus.writer,
            _ => clickBus.events
              .withCurrentValueOf($value)
              .withCurrentValueOf($availableValues)
              .map(t => (t._2, t._3)) -->
              Observer[(T, List[T])](onNext = {
                case (v, values) =>
                  inputString.set(v.toString)
                  //                valueVar.set(Some(v))
                  setItemsToShow(v.toString, values)
                  hasError.set(false)
                  showMenu.set(false)
              })

          )
        })
      ),

      _ => onMountCallback(ctx => {
        ctx.addStyleToNestedShadowElement(".mdc-menu.mdc-menu-surface", ".mdc-menu-surface", "box-shadow", "2px 2px 5px rgb(0 0 0 / 20%)")


      })
    ),


  )
}
