package root_pages.removal

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ServiceType.ServiceType
import common.airstream_ops.AirStreamOps
import common.{AurinkoAccount, ServiceType, TabAccounts, TabCompanyOps}
import org.scalajs.dom.html
import router.{AuthPage, RemovalPage, teamsBotRouter}
import service.ServiceManager
import service.tabs_api.TabCompany.TabCompany
import service.tabs_api.{MsRemoveEvent, TabsApi, microsoftTeams}
import wvlet.log.Logger


case class RemovalComponent($page: Signal[RemovalPage], tabsApi: TabsApi) {

  private val log = Logger.of[RemovalComponent]

  val startBus = new EventBus[MsRemoveEvent]
  val completeBus = new EventBus[Unit]

  def removeCallback(event: MsRemoveEvent): Unit = {
    startBus.emit(event)
  }

  microsoftTeams.settings.registerOnRemoveHandler(removeCallback)

  val needAuthBus: EventBus[Option[ServiceType]] = new EventBus[Option[ServiceType]]
  val navigateToAuth: Observer[Option[ServiceType]] = Observer[Option[ServiceType]](onNext = t => teamsBotRouter.pushState(AuthPage(t.map(_.value))))
  val logoutAccountsBus: EventBus[List[AurinkoAccount]] = new EventBus[List[AurinkoAccount]]

  val accsBus = new EventBus[TabAccounts]
  val $accounts: EventStream[TabAccounts] = accsBus.events

  val node: ReactiveHtmlElement[html.Div] = div(

    cls := "slds-align--absolute-center",

    needAuthBus.events --> navigateToAuth,

    logoutAccountsBus.events.flatMap(l => EventStream.combineSeq(l.map(_.id).map(tabsApi.logoutAccount)))
      .mapTo(tabsApi.crmType) --> navigateToAuth,

    tabsApi.$tabOwner.changes.collect{case Some(v) => v} --> Observer[TabCompany](c => ServiceManager.UILogging.setApiUrl(c.toServiceType)),


    $page.map{route =>
        if (tabsApi.needClientId) tabsApi.setClientId(route.clientId.getOrElse(throw new Exception("Missing au client id")))
        if (tabsApi.needToDefineTabOwner) {
          tabsApi.setTabCompany(route.svc.getOrElse("Repfabric"))
        }
      }
      .flatMap(_ => {
        if (tabsApi.missingMsContext) tabsApi.setMsContextIds()
        else ().streamed
      })
      .flatMap(_ => tabsApi.accounts) --> Observer[List[AurinkoAccount]](onNext = { list =>

      val primaryList = list.filter(_.userAccountType == "primary")

      if (primaryList.isEmpty)
        needAuthBus.emit(Some(ServiceType.office365))
      else {
        val secondaryList = list.filter(ac =>
          ac.userAccountType == "secondary" && ac.tokenStatus.ok && tabsApi.crmType.forall(_ == ac.serviceType)
        )

        if (secondaryList.isEmpty) {
          needAuthBus.emit(tabsApi.crmType)
        } else if (secondaryList.length > 1) {
          logoutAccountsBus.emit(secondaryList)
        } else {
          accsBus.emit(TabAccounts(primaryList.head, secondaryList.head))
        }
      }
    }),

    $accounts.flatMap{
      case accs if tabsApi.isPrivateChannel => for {
        savedId <- tabsApi.getGroupIdFromStorage(accs.primaryAccount.id)
        userTeams <- if (savedId.isEmpty) tabsApi.getUserTeams(accs.primaryAccount.id).map(Some(_)) else None.streamed
        teamId <- if (savedId.isEmpty) {
          EventStream
            .fromSeq(userTeams.getOrElse(Nil))
            .flatMap(team => tabsApi.getTeamPrivateChannels(team.id, accs.primaryAccount.id).map(list => team.id -> list))
            .flatMap{case (teamId, list) => EventStream.fromSeq(list).map(i => teamId -> i)}
            .filter(_._2.id == tabsApi.channelId)
            .map(_._1)
            .combineWith(().streamed)
            .map(c => Some(c))
        } else None.streamed
        _ <- if (teamId.isDefined) tabsApi.saveChannelTeamId(accs.primaryAccount.id, teamId.get) else ().streamed
        result <- (
            if (savedId.isDefined) savedId.get
            else teamId.getOrElse(throw new Exception("Failed to get private channel id"))
          ).streamed
      } yield result

      case _ => EventStream.empty
    } --> Observer[String](onNext = id =>  tabsApi.msGroupId = Some(id)),



    child <-- $accounts.map(accs => p(
      startBus.events
        .flatMap{ removeEvent =>
          tabsApi.deleteChannelManufacturer(
            accs.secondaryAccount.authOrgId.getOrElse(throw new Exception("Missing client org id")),
            accs.primaryAccount.id
          ).mapTo(removeEvent)}
        --> Observer[MsRemoveEvent](onNext = _.notifySuccess()),

      "Please confirm the deletion by clicking Remove."
    ))
  )


}

