package root_pages.tab_content

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Icon, IconButton, Menu}
import com.raquo.domtypes.generic.codecs.BooleanAsTrueFalseStringCodec
import com.raquo.domtypes.generic.nodes
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.{ChildNode, ReactiveHtmlElement}
import common.AddinCardType.AddinCardType
import common.CssSize.CssSize
import common.ServiceType.ServiceType
import common.airstream_ops.AirStreamOps
import common.microsoft_cards.{AdaptiveActionOpenUrl, AdaptiveCard, AdaptiveTextBlock, Fact, FactSet, MsMessageContent}
import common.ui.SlidePanelComponent
import common.{AddinCard, AddinCardType, AddinSection, AddinSectionType, CssSize, OnClickOptions, ServiceType, StringOps, TabAccounts, TokenData, dateTimeFormatter, graphic}
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import org.scalajs.dom.{html, raw}
import org.scalajs.dom.raw.{HTMLElement, NonDocumentTypeChildNode}
import router.{AuthPage, teamsBotRouter}
import service.ServiceManager
import service.clipboard_service.ClipboardServiceImp
import service.tabs_api.{SubEntityDetails, TabsApi}
import service.ui_logging.UILogger
import wvlet.log.Logger

import java.time.{Instant, ZoneId}
import scala.collection.immutable.HashMap
import scala.scalajs.js.URIUtils

case class CrmContent(accounts: TabAccounts, tabsApi: TabsApi) {
	private val log = Logger.of[CrmContent]
	private val uiLogger = UILogger("CrmContent", ServiceManager.UILogging)

	val msAppId: Signal[Option[String]] = tabsApi.getMicrosoftAppId.map(Some(_)).startWith(None)

	val localClipboardService: ClipboardServiceImp = ClipboardServiceImp()

	val showProfileMenu = Var(false)

	val currentObjectPage = Var(tabsApi.msSubEntityDetails)
	val previousObjectPages = Var("")


	def iconByCrmType(crmType: Option[String]): SfIconOptions = crmType
		.flatMap(t =>
			Icons.optionsMap.get(t.toLowerCase)
				.orElse(Icons.optionsMap.get(t.toLowerCase + "s"))
		)
		.getOrElse(Icons.optionsMap("unknown"))

	val tokenBus: EventBus[Option[String]] = new EventBus[Option[String]]

	def requestToken: EventStream[String] = for {
		encodedId <- tabsApi.decodeChannelId(
			tenantId = tabsApi.tenantId,
			channelId = tabsApi.channelId,
			teamId = tabsApi.groupId)

		token <- tabsApi.generateToken(
			accounts.secondaryAccount.id,
			TokenData(
				encodedChannelData = encodedId,
				tenantId = tabsApi.tenantId,
				personalOfficeAccId = Option.when(tabsApi.isPrivateChannel) {
					accounts.primaryAccount.id.toString
				}
			)
		)
	} yield token

	val showTokenPopup: Var[Boolean] = Var(false)

	def generateDeepLink(objId: String, objType: String, appId: String, objRecordTypeId: Option[String], channelId: String): String = {

		val objectSubEntityDetails = SubEntityDetails(
			sfOrgId = tabsApi.subEntityDetails.sfOrgId,
			objId = objId,
			objType = objType,
			objRtId = objRecordTypeId,
			serviceType = tabsApi.subEntityDetails.serviceType
		)

		val encodedContext =
			URIUtils.encodeURIComponent(
				Map(
					"subEntityId" -> objectSubEntityDetails.toContextString(Some(tabsApi.convoId)),
					"channelId" -> channelId
				).asJson.toString

				//				s"""{"subEntityId": \n"${objectSubEntityDetails.toContextString(tabsApi.convoId)}", \n"channelId": "${tabsApi.channelId}"}"""
			)

		val link = s"https://teams.microsoft.com/l/entity/$appId/yxl_sync_$channelId?context=$encodedContext"

		log.info(s"deep link: $link")
		uiLogger.info(s"share action. Deep link: $link")
		link
	}

	def objectShareMessage(
		                      headerCard: AddinCard,
		                      details: List[AddinCard],
		                      //		                      objName: String,
		                      //		                      objId: String,
		                      //		                      objType: String,
		                      appId: String,
		                      //		                      label: String,
		                      //		                      objRtId: Option[String],
		                      channelId: String,
		                      serviceType: ServiceType,
		                      objCRMLink: Option[String]

	                      ): MsMessageContent = {

		val objId = headerCard.crmObjectId.getOrElse(throw new Exception("Missing card.crmObjectId"))
		val objType = headerCard.crmObjectType.getOrElse(throw new Exception("Missing card.crmObjectId"))

		val objLink: Option[String] = objCRMLink
			.orElse(accounts.secondaryAccount.serverUrl
				.filter(_ => accounts.secondaryAccount.serviceType == ServiceType.salesforce)
				.map(url => s"$url/$objId")) //to support old salesforce cards


		val shareMessage = objLink.map(l => AdaptiveTextBlock(text = s"$objType [[View in ${serviceType.label}]]($l)"))

		val nameFact = headerCard.fieldName.map(fn => Fact(fn, headerCard.lineText.headOption.getOrElse("")))

		val facts = nameFact.toList :::
			details
				.filter(df => df.lineTopLabel.nonEmpty && df.lineText.nonEmpty)
				.take(3)
				.map(df => Fact(df.lineTopLabel.head, df.lineText.head))

		val deepLink = generateDeepLink(objId, objType, appId, headerCard.crmObjectRtId, channelId)

		val action = AdaptiveActionOpenUrl(title = Some("Preview"), url = deepLink)

		val card = AdaptiveCard(body = shareMessage.toList ::: FactSet(facts = facts) :: Nil, actions = action :: Nil)

		MsMessageContent(card = card, message = "Take a look at this record:")
	}

	def commonCardView[T](
		                     iconOptions: Option[SfIconOptions] = None,
		                     text: String,
		                     cardType: AddinCardType = AddinCardType.string,
		                     bottomLabel: Option[String] = None,
		                     topLabel: Option[String] = None,
		                     clickOptions: Option[OnClickOptions[T]] = None,
		                     iconSize: CssSize = CssSize.small,
		                     textCls: String = "",
		                     hoverable: Boolean = true,
		                     actions: List[ReactiveHtmlElement[HTMLElement]] = Nil
	                     ): Div = {
		div(
			cls := "slds-grid slds-grid--vertical-align-center simple-card" + (if (hoverable) " hoverable" else ""),
			cls.toggle("clickable")(clickOptions.nonEmpty),

			iconOptions.map(icon => div(
				cls := "slds-media__figure slds-m-right--medium",
				span(
					cls := s"slds-icon_container slds-icon_container_circle slds-icon-standard-color icon-container--$iconSize",
					//					cls := s"slds-icon_container slds-icon_container_circle slds-icon-${icon.css} icon-container--$iconSize",
					svg.svg(
						svg.cls := s"slds-icon slds-icon--$iconSize",
						svg.customSvgAttr("aria-hidden", BooleanAsTrueFalseStringCodec) := true,
						svg.use(svg.xlinkHref(s"/assets/icons/${icon.folder}/svg/symbols.svg#${icon.name}"))
					)
				))
			),

			div(
				cls := "slds-grow",
				div(
					cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-size--1-of-1",

					topLabel.map(label => small(cls := "grey ", label)),
					div(
						cls := "slds-grid slds-grid--vertical-align-center",
						actions.map(el => div(cls := "slds-m-left--x-small text--level-2 ", el))
					)
				),

				div(
					cls := "slds-grid slds-grid--vertical-align-center",

					cardType match {
						case AddinCardType.text => pre(text, cls := "slds-m-around--none", overflow := "auto")
						case AddinCardType.datetime => p(
							Instant.parse(text)
							.atZone(ZoneId.systemDefault)
							.toLocalDateTime.format(dateTimeFormatter))
						case _ =>  p(text, cls := textCls)
					}

				),

				bottomLabel.map(label => small(cls := "grey", label)),
				clickOptions.map { ops =>
					composeEvents(onClick)(_.flatMap(e =>
						if (ops.effect.nonEmpty) ops.effect.get
						else e.asInstanceOf[T].streamed)) --> ops.observer
				}

			)
		)
	}

	def detailsCard(card: AddinCard): Div = card.`type` match {

		case AddinCardType.crm =>
			commonCardView(
				text = card.lineText.mkString(" "),
				cardType = card.`type`,
				topLabel = Some(card.lineTopLabel.mkString(" ")),
				clickOptions = Option.when(!card.disableNavigation) {
					OnClickOptions(

						effect = Some(
							Some(SubEntityDetails(
							tabsApi.subEntityDetails.sfOrgId,
							card.crmObjectType.getOrElse(throw new Exception("Empty crmObjectType")),
							card.crmObjectId.getOrElse(throw new Exception("Empty crmObjectId")),
							card.crmObjectRtId,
							tabsApi.subEntityDetails.serviceType)).streamed),

						observer = Observer[Option[SubEntityDetails]](value => {

							if (!value.exists(v => currentObjectPage.now.contains(v))) {

								previousObjectPages.update(p => {
									currentObjectPage.now
										.getOrElse(throw new Exception("currentObjectPage is empty"))
										.toContextString(tabsApi.msConvoId) + "\\" + p
								})

								currentObjectPage.set(value)
							}
						})
					)
				}
			)

		case AddinCardType.string =>
			commonCardView(
				text = card.lineText.mkString(" "),
				cardType = card.`type`,
				topLabel = Some(card.lineTopLabel.mkString(" ")),
				bottomLabel = Some(card.lineBottomLabel.mkString(" ")),
				clickOptions =
					if (
						card.lineText.length == 1 &&
							(("phone" :: "mobile" :: Nil).exists(s => card.lineTopLabel.exists(_.containsIgnoreCase(s))) ||
								card.lineTopLabel.exists(_.containsIgnoreCase("email")) && card.lineText.head.contains("@"))
					) {

						val p =
							if (card.lineTopLabel.exists(_.containsIgnoreCase("email"))) "mailto:"
							else "tel:"

						Some(OnClickOptions[dom.MouseEvent](
							effect = None,
							observer = Observer[dom.MouseEvent](onNext = _ => dom.window.open(url = s"$p${card.lineText.head}", target = "_blank"))
						))
					}
					else None

			)

		case _ => commonCardView(
			text = card.lineText.mkString(" "),
			cardType = card.`type`,
			topLabel = Some(card.lineTopLabel.mkString(" ")),
			bottomLabel = Some(card.lineBottomLabel.mkString(" "))
		)

	}

	def crmCard(card: AddinCard): Div = commonCardView(
		iconOptions = Some(iconByCrmType(if (card.crmObjectSubtype.nonEmpty) card.crmObjectSubtype else card.crmObjectType)),
		text = card.lineText.mkString(" "),
		cardType = card.`type`,
		bottomLabel = Some(card.lineBottomLabel.mkString(" ")),
		clickOptions = Option.when(!card.disableNavigation) {
			OnClickOptions(
				effect = Some(Some(SubEntityDetails(
					tabsApi.subEntityDetails.sfOrgId,
					card.crmObjectType.getOrElse(throw new Exception("Empty crmObjectType")),
					card.crmObjectId.getOrElse(throw new Exception("Empty crmObjectType")),
					card.crmObjectRtId,
					serviceType = tabsApi.subEntityDetails.serviceType
				)).streamed),
				observer = Observer[Option[SubEntityDetails]](value => {

					if (!value.exists(v => currentObjectPage.now.contains(v))) {

						previousObjectPages.update(p => {

							currentObjectPage.now
								.getOrElse(throw new Exception("currentObjectPage is empty"))
								.toContextString(tabsApi.msConvoId) + "\\" + p

						})
						currentObjectPage.set(value)
					}
				})
			)
		}

	)

	def renderSection(addinSection: AddinSection): EventStream[ChildNode[raw.Node with NonDocumentTypeChildNode] with nodes.Node] = {

		uiLogger.info(s"Render section ${addinSection.header.getOrElse("")}")

		val sectionData = addinSection.loadPath match {
			case None => addinSection.streamed
			case Some(path) => tabsApi.loadAddinSection(path, accounts.secondaryAccount.id)
		}

		sectionData.map { section =>
			section.`type` match {

				case AddinSectionType.details => SlidePanelComponent(
					collapsedElementsDisplayed = 3,
					$listOfContent = section.cards.map(detailsCard).signaled
				).node

				case t if t == AddinSectionType.cardList || t == AddinSectionType.cardSelection =>
					SlidePanelComponent(
						$header = section.header.getOrElse("").signaled,
						collapsedElementsDisplayed = 3,
						$listOfContent = section.cards.map(crmCard).signaled,
						noEntriesMessage = true
					).node

				case _ => emptyNode
			}

		}
	}

	val headerElement: ReactiveHtmlElement[html.Div] = div(
		cls := "tab-header no-left-padding",

		div(
			cls := "slds-grid slds-grid--vertical-align-center slds-m-right--medium",

			div(
				cls := "back-arrow-container",
				Icon(
					_ => cls := "moved-left--x-small clickable moved-left light x-small",
					_ => cls <-- previousObjectPages.signal.map { case "" => "hidden" case _ => "" },
					//						_,
					_ => "arrow_back",
					_ => onClick.mapTo(previousObjectPages.now) --> Observer[String](v => {
						previousObjectPages.set(v.split('\\').toList.tail.mkString("\\"))
						log.info(s"setting current: ${v.split('\\').toList.head}")
						currentObjectPage.set(Some(SubEntityDetails(v.split('\\').toList.head)))
					}),
				)
			),

			//				)
			//			},
			img(src := "assets/yoxel/yoxel-black.svg", height := "calc(var(--tab-header-height) / 2)"),
		),


		div(
			cls := "slds-grid slds-grid-vertical-align--center",

			div(
				position := "relative",
				material.IconButton(
					_ => cls := "light profile-icon",
					_.icon := "account_circle",
					_ => onClick.mapTo(!showProfileMenu.now) --> showProfileMenu
				),
				{
					val width = Var(250)
					val userInfoElem = div(
						cls := "slds-grid slds-grid--vertical-align-center",
						paddingLeft := "var(--mdc-list-side-padding, 16px)",
						paddingRight := "var(--mdc-list-side-padding, 16px)",

						Icon(
							_ => "person",
							_ => cls := "xx-large slds-m-right--small light no-left-margin",
						),
						div(
							cls := "slds-p-top--medium slds-p-bottom--small slds-grid slds-grid--vertical",

							strong(s"${accounts.primaryAccount.name.getOrElse("")}", cls := "subtitle--level-3 slds-m-bottom--xx-small"),
							span(s"${accounts.primaryAccount.email.getOrElse("")}"),

						)
					)

					val crmInfoElem = div(
						cls := "slds-p-top--medium slds-p-bottom--small slds-grid slds-grid--vertical",
						paddingLeft := "var(--mdc-list-side-padding, 16px)",
						paddingRight := "var(--mdc-list-side-padding, 16px)",

						span(
							s"${accounts.secondaryAccount.serviceType.label} organization:",
							cls := "subtitle--level-3 slds-m-bottom--small light"
						),

						accounts.secondaryAccount.authOrgId.map(span(_)),

						span(
							cls := "text--level-2 light",
							s"${
								accounts.secondaryAccount
									.loginString
									.orElse(accounts.secondaryAccount.email)
									.getOrElse("")
							}",
						),
					)

					Menu(
						_ => cls := "profile-menu wide",
						_.open <-- showProfileMenu,
						_.onClosed.mapTo(false) --> showProfileMenu,
						_.onOpened.mapTo(userInfoElem.ref.scrollWidth) --> width,
						_ => left <-- width.signal.map(v => s"${30 - v}px"),

						_ => userInfoElem,
						_ => li(cls := "mdc-list-divider"),

						_ => crmInfoElem,
						_ => li(cls := "mdc-list-divider"),


						_ => if (tabsApi.msChannelId.nonEmpty && tabsApi.msGroupId.nonEmpty) {

							ListItem(
								_ => cls := "primary",
								_ => graphic := "icon",
								_.slots.graphic(material.Icon(_ => "generating_tokens", _ => cls := "light")),
								_.slots.default(span(
									if (accounts.secondaryAccount.serviceType == ServiceType.salesforce) "Show integration token"
									else "Show webhook URL"
								)),
								_ => composeEvents(onClick)(_.
									flatMap(_ => requestToken)
									.map(t =>
										if (accounts.secondaryAccount.serviceType == ServiceType.salesforce) t
										else s"https://signals.yoxel.com/push/generic?token=$t"
									).map(Some(_))) --> tokenBus.writer
							) :: li(cls := "mdc-list-divider") :: Nil
						} else None,

						_ => ListItem(
							_ => cls := "blue",
							_ => graphic := "icon",
							_.slots.graphic(material.Icon(_ => "exit_to_app")),
							_.slots.default(span("Log out", cls := "blue")),
							_ => composeEvents(onClick)(_.flatMap(_ => for {
								_ <- tabsApi.logoutAccount(accounts.secondaryAccount.id)
								_ <- tabsApi.logout
							} yield ())) --> Observer[Unit](onNext = _ => {
								ServiceManager.UILogging.resetToken()
								teamsBotRouter.pushState(AuthPage(Some(ServiceType.office365.value), None))
							})
						)
					)
				}
			)
		),
	)

	val showHeader = Var(tabsApi.msSubEntityDetails.isEmpty)


	val node: Div = div(
		headerElement,

		Dialog(
			_.heading := (
				if (accounts.secondaryAccount.serviceType == ServiceType.salesforce) "Integration channel token"
				else "Webhook url"),
			_.open <-- tokenBus.events.map(_.nonEmpty),
			_.onClosing.mapTo(None) --> tokenBus.writer,
			_.slots.default(
				div(
					cls := "slds-grid slds-grid--vertical-align-center",

					p(
						child.text <-- tokenBus.events.collect { case Some(v) => v },
						cls := "token-container slds-m-right--small"
					),
					child <-- tokenBus.events.collect {
						case Some(token) => IconButton(
							_.icon := "content_copy",
							_ => onClick --> Observer[dom.MouseEvent](onNext = _ => localClipboardService.copy(token))
						)
					},
					localClipboardService.area

				)
			),
			_.slots.primaryAction(
				Button(
					_.label := "Done",
					_.raised := true,
					_ => onClick.mapTo(None) --> tokenBus.writer
				)
			)
		),

		if (tabsApi.msSubEntityDetails.isEmpty) {
			//			if (!tabsApi.isMobile) {
			tabsApi.msConvoId.map(cid => tabsApi.openConvo(conversationId = Some(cid)))
			//			}

			div(
				cls := "tab-page-wrapper slds-align--absolute-center",
				div(
					cls := "slds-p-horizontal--large",
					p(
						cls := "title--level-2 slds-m-bottom--large",
						s"Welcome, ${
							accounts.primaryAccount.name
								.orElse(accounts.secondaryAccount.name)
								.getOrElse("")
								.split(" ")
								.headOption
								.getOrElse("")
						}!"
					),
					p(
						cls := "slds-m-bottom--small",
						s"You are all set and can use the @Yoxel Signals commands in the ‘Posts’ tab.",
					),
					p(
						s" ${tabsApi.tabCompany} admin can configure workflow signals, ",
						a(
							"here is how",
							href := "https://helpdocs.yoxel.com/flow-signals/configuring-salesforce-flow-signals",
							target := "_blank"),
						"."
					)
				)
			)
		} else {

			if (!accounts.secondaryAccount.authOrgId.contains(tabsApi.subEntityDetails.sfOrgId)) {
				ServiceManager.Messages
					.addMessage(s"Can not show this ${tabsApi.subEntityDetails.objType} because it is from another Organization: " +
						s"${tabsApi.subEntityDetails.sfOrgId}.\nYou are currently logged in " +
						s"${accounts.secondaryAccount.authOrgId.getOrElse("[missing authOrgId]")}.")

				emptyNode
			}

			else div(
				if (!tabsApi.isMobile) {
					tabsApi.msConvoId.map { cId =>
						tabsApi.openConvo(
							subEntityId = Some(tabsApi.subEntityDetails.sfOrgId),
							title = Some(tabsApi.subEntityDetails.objType),
							conversationId = Some(cId)
						) --> Observer[Unit](onNext = _ => log.info("opened convo"))
					}
				} else None,

				tabsApi.msSubEntityDetails.map(_ =>
					onClick.mapTo(false) --> showHeader
				),

				child <-- currentObjectPage.signal
					.map(_.getOrElse(throw new Exception("objectPageVar is not defined")))
					.flatMap(subEnt => tabsApi.getAddinObject(
						subEnt.objType,
						subEnt.objId,
						accounts.secondaryAccount.id,
						subEnt.objRtId
					))
					.map(addinPage => {

						val relatedSections = addinPage.sectionsLoadPath
							.map(path => tabsApi.getAddinLoadPath(path, accounts.secondaryAccount.id))
							.getOrElse(Nil.streamed)

						div(
							cls := "tab-page-wrapper",

							div(
								cls := "navigation-container"
							),

							div(
								cls := "",

								child.maybe <-- msAppId.map { appId =>

									addinPage.headerCard.map { card =>

										val crmViewAction = card
											.crmObjectLink
											.orElse(card
												.crmObjectId.flatMap(
												id => accounts.secondaryAccount
													.serverUrl
													.filter(_ => accounts.secondaryAccount.serviceType == ServiceType.salesforce)
													.map(url => s"$url/$id"))) // to support old salesforce cards
											.map(url => a(
												s"[View in ${tabsApi.subEntityDetails.serviceType.label}]",
												href := url,
												target := "_blank",
											))

										val postToChatAction = tabsApi.msChannelId.flatMap { chId =>
											appId.map(id => a(
												s"[Share]",
												composeEvents(onClick)(_.flatMap(
													_ => tabsApi.postMessageToConvo(
														content =
															objectShareMessage(
																headerCard = card,
																details = addinPage
																	.sections.find(_.`type` == AddinSectionType.details)
																	.getOrElse(throw new Exception("Failed to get object details"))
																	.cards,
																appId = id,
																channelId = chId,
																serviceType = tabsApi.subEntityDetails.serviceType,
																card.crmObjectLink
															),
														primaryAccId = accounts.primaryAccount.id,
														convoId = tabsApi.convoId,

													)

												)) --> Observer[String](onNext = _ => log.info("posted"))
											))
										}

										div(
											cls := "header-card--container",
											commonCardView(
												iconOptions = Some(iconByCrmType(if (card.crmObjectSubtype.nonEmpty) card.crmObjectSubtype else card.crmObjectType)),
												text = card.lineText.mkString(" "),
												cardType = card.`type`,
												topLabel = Some(card.lineTopLabel.mkString(" ")),
												bottomLabel = Some(card.lineBottomLabel.mkString(" ")),
												iconSize = CssSize.medium,
												textCls = "title--level-1",
												hoverable = false,
												actions = (crmViewAction :: postToChatAction :: Nil).flatten
											)
										)
									}

								},
								div(
									children <-- EventStream.combineSeq(addinPage.sections.map(renderSection)),
									children <-- relatedSections.flatMap(l => EventStream.combineSeq(l.map(renderSection))),
								),
							)
						)
					}
					)
			)

		})


}

case class SfIconOptions(
	                        css: String,
	                        name: String,
	                        folder: String
                        )

object Icons {
	val optionsMap: Map[String, SfIconOptions] = HashMap(
		"opportunity" -> SfIconOptions("standard-opportunity", "new_opportunity", "action-sprite"),
		"opportunities" -> SfIconOptions("standard-opportunity", "new_opportunity", "action-sprite"),
		"cases" -> SfIconOptions("standard-case", "new_case", "action-sprite"),
		"deals" -> SfIconOptions("standard-case", "new_case", "action-sprite"),
		"accounts" -> SfIconOptions("standard-account", "new_account", "action-sprite"),
		"company" -> SfIconOptions("standard-account", "new_account", "action-sprite"),
		"companies" -> SfIconOptions("standard-account", "new_account", "action-sprite"),
		"businesses" -> SfIconOptions("standard-account", "new_account", "action-sprite"),
		"businesscenters" -> SfIconOptions("standard-account", "new_person_account", "action-sprite"),
		"business" -> SfIconOptions("standard-account", "new_account", "action-sprite"),
		"contacts" -> SfIconOptions("standard-contact", "new_contact", "action-sprite"),
		"embeddedCustomContacts" -> SfIconOptions("standard-contact", "new_contact", "action-sprite"),
		"embeddedDndSettingContacts" -> SfIconOptions("standard-contact", "new_contact", "action-sprite"),
		"embeddedDndSettingsContacts" -> SfIconOptions("standard-contact", "new_contact", "action-sprite"),
		"leads" -> SfIconOptions("standard-lead", "new_lead", "action-sprite"),
		"emails" -> SfIconOptions("standard-email", "email", "action-sprite"),
		"meetings" -> SfIconOptions("standard-event", "new_event", "action-sprite"),
		"events" -> SfIconOptions("standard-event", "new_event", "action-sprite"),
		"holidays" -> SfIconOptions("standard-event", "new_event", "action-sprite"),
		"tasks" -> SfIconOptions("standard-task", "new_task", "action-sprite"),
		"calls" -> SfIconOptions("standard-log-a-call", "log_a_call", "action-sprite"),
		"personaccounts" -> SfIconOptions("standard-person-account", "new_person_account", "action-sprite"),
		"campaigns" -> SfIconOptions("standard-campaign", "new_campaign", "action-sprite"),
		"users" -> SfIconOptions("standard-user", "user", "action-sprite"),
		"products" -> SfIconOptions("standard-record", "record", "action-sprite"),
		"new" -> SfIconOptions("standard-task", "new", "action-sprite"),
		"jobs" -> SfIconOptions("standard-job-profile", "job_profile", "action-sprite"),
		"conversations" -> SfIconOptions("standard-record", "record", "action-sprite"),
		"emailtemplates" -> SfIconOptions("standard-job-profile", "user", "action-sprite"),
		"forcasts" -> SfIconOptions("standard-job-profile", "adjust_value", "action-sprite"),
		"quotes" -> SfIconOptions("standard-job-profile", "quote", "action-sprite"),
		"tags" -> SfIconOptions("standard-job-profile", "quote", "action-sprite"),
		"notes" -> SfIconOptions("standard-job-profile", "record", "action-sprite"),
		"messages" -> SfIconOptions("standard-job-profile", "share_post", "action-sprite"),
		"contracts" -> SfIconOptions("standard-job-profile", "new_note", "action-sprite"),
		"documents" -> SfIconOptions("standard-job-profile", "new_note", "action-sprite"),
		"reports" -> SfIconOptions("standard-job-profile", "new_note", "action-sprite"),
		"revenuelineitems" -> SfIconOptions("standard-job-profile", "update", "action-sprite"),
		"lineitems" -> SfIconOptions("standard-job-profile", "update", "action-sprite"),
		"purchases" -> SfIconOptions("standard-job-profile", "update", "action-sprite"),
		"purchasedlineitems" -> SfIconOptions("standard-job-profile", "update", "action-sprite"),
		"shifts" -> SfIconOptions("standard-job-profile", "google_news", "action-sprite"),
		"escalations" -> SfIconOptions("standard-job-profile", "priority", "action-sprite"),
		"campaigns" -> SfIconOptions("standard-job-profile", "new_campaign", "action-sprite"),
		"tickets" -> SfIconOptions("standard-job-profile", "follow", "action-sprite"),
		"teams" -> SfIconOptions("standard-document", "team_member", "standard-sprite"),
		"emailaddresses" -> SfIconOptions("standard-document", "read_receipts", "standard-sprite"),
		"emailaddress" -> SfIconOptions("standard-document", "read_receipts", "standard-sprite"),
		"emailparticipants" -> SfIconOptions("standard-document", "read_receipts", "standard-sprite"),

		"unknown" -> SfIconOptions("standard-document", "sobject", "standard-sprite")
	)
}
