package service.ui_logging

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import common.ServiceType.ServiceType
import common.airstream_ops.AirStreamOps
import common.{CirceStringOps, JsonEnum, ServiceType}
import io.circe.generic.auto.exportDecoder
import io.circe.parser
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import service.exceptions.{AurinkoApiException, NotAuthorizedException, XhrFailedException}
import service.http.ApiRequester
import service.origins.Origins
import service.ui_logging
import service.ui_logging.LogLevel.LogLevel
import wvlet.log.Logger

import java.time.{Instant, ZoneId}
import scala.util.{Failure, Success, Try}

object LogLevel extends Enumeration with JsonEnum {
	type LogLevel = Value

	val NONE, ERROR, WARN, INFO, FINE = Value

	val all: Seq[Value] = NONE :: ERROR :: WARN :: INFO :: FINE :: Nil

}

case class UILoggingService() {

	private val log = Logger.of[UILoggingService]
	private val apiRequester = ApiRequester("", validateResponse)
	private var apiUrl: Option[String] = Some(Origins.apiUrl)

	def setApiUrl(crmType: Option[ServiceType]) = apiUrl = Some(
//		crmType match {
//		case ServiceType.repfabric => "https://aurinko.repfabric.info/v1"
//		case _ => "https://aurinko.yoxel.com/v1"
//	}
		Origins.apiUrl
	)

	private var token: Option[String] = None
	private val logLevel: Var[Option[LogLevel]] = Var(None)
	private var auClientId: Option[String] = None

	private def init: EventStream[UILoggingInitResponse] = apiRequester
		.post(s"${apiUrl.get}/addin/logging/init",
			headers = Map("X-Aurinko-ClientId" -> auClientId.getOrElse(throw new Exception("auClientId is Not defined"))),
			showSpinner = false
		)
		.map(_.decodeAs[UILoggingInitResponse])

	private val messages = collection.mutable.Buffer[String]()

//	val mes: Var[List[String]] = Var(Nil)

	private val updateTokenTrigger = new EventBus[Unit]

	def updateToken(): Unit = updateTokenTrigger.emit(())

	def log(message: String, level: LogLevel, className: String): Unit = {

//		mes.update(_ :+ message.toLoggingFormat(level, className))

//		log.info(s"maybe send: $message")
		if (level.allowed || logLevel.now.isEmpty) {

//			log.info(s"add message: $message ${messages.mkString(" ,")}")
			messages += message.toLoggingFormat(level, className)

			if (level.allowed && token.nonEmpty && apiUrl.nonEmpty) {

				log.info(s"log bus emit $message")
				logBus.emit(())
			}

		}
	}

	private val userDataBus: EventBus[(Int, String)] = new EventBus[(Int, String)]

	def getLevelAndInit(accId: Int, auClId: String): Unit = {
		auClientId = Some(auClId)
		userDataBus.emit(accId -> auClId)
	}
	def resetToken(): Unit = {
		messages.clear()
		token = None
	}

	private val updateTokenBinder = updateTokenTrigger.events
		.flatMap(_ => init) --> Observer[UILoggingInitResponse](t => {
		token = Some(t.token)
		if (messages.nonEmpty) logBus.emit(())
	})

	private val logBus = new EventBus[Unit]

	private def getLogLevel(accId: Int, auClientId: String): EventStream[ui_logging.LogLevel.Value] = apiRequester
		.get(s"${apiUrl.get}/storage/user/uiLogsLevel",
			headers = Map("X-Aurinko-AccountId" -> accId.toString, "X-Aurinko-ClientId" -> auClientId),
			showSpinner = false)
		.recoverToTry
		.map{
			case Failure(exception) => "{}"
//				throw exception
				case Success(value) => value
		}
		.map(_.decodeAs[Map[String, String]].get("uiLogsLevel") match {
			case Some(v) if v.nonEmpty => Try(LogLevel.withName(v)).getOrElse(LogLevel.NONE)
			case _ => LogLevel.NONE
		})

	private def sendMessages() = {
		val d = messages.clone()
		log.info(s"send: messages $messages; d: ${d.mkString(", ")}")

		messages.clear()
		if (d.nonEmpty && token.nonEmpty && apiUrl.nonEmpty) {
			apiRequester.post(
				s"${apiUrl.get}/addin/logging",
				body = Some(Map("lines" -> d).asJson),
				headers = Map("Authorization" -> s"Bearer ${token.get}"),
				showSpinner = false
			).recoverToTry.map {
				case Failure(_: NotAuthorizedException) =>
					messages.insertAll(0, d)
					token = None
					updateToken()
				case Failure(e) => throw e
				case Success(_) => ()
			}
		} else ().streamed
	}

	val binders: List[Binder[ReactiveElement.Base]] = List(

			userDataBus.events.flatMap(t => getLogLevel(t._1, t._2)).map(Some(_)) --> logLevel,

			logLevel.signal.changes
				.filter(!_.contains(LogLevel.NONE))
				.flatMap(_ => init) --> Observer[UILoggingInitResponse](resp => {
				token = Some(resp.token)
				if (messages.nonEmpty) {
					logBus.emit(())
				}
			}),

		logLevel.signal.changes
			.filter(_.contains(LogLevel.NONE))
			 --> Observer[Option[LogLevel]](_ => messages.clear()),

			logBus.events
				.debounce(1000)
				.filter(_ => token.nonEmpty && apiUrl.nonEmpty)
				.flatMap(_ => sendMessages()) --> Observer.empty,

			updateTokenBinder
	)

	def validateResponse(resp: dom.XMLHttpRequest): String = resp.status match {
		case c if c >= 200 && c < 300 => resp.responseText
		case c if c == 401 => throw NotAuthorizedException()
		case c if c == 0 => throw XhrFailedException()
		case _ =>
			log.info(resp.status)
			throw parser.decode[AurinkoApiException](resp.responseText).fold(
				_ => new Exception(resp.responseText),
				v => v
			)
	}

	implicit class LogLevelConfigs(level: LogLevel) {
		def allowed: Boolean = {

			val allowed = logLevel.now.exists(l => LogLevel.all.indexOf(level) <= LogLevel.all.indexOf(l))
//			log.info(s"message level: $level allowed: ${logLevel.now} $allowed")
			allowed
		}
	}

	implicit class LoggingMessageOpps(message: String) {
		def toLoggingFormat(level: LogLevel, className: String) = s"${Instant.now().atZone(ZoneId.of("UTC"))} $level. $className: $message"
	}
}

case class UILogger(private val className: String, private val logging: UILoggingService) {

	def error(message: String): Unit = logging.log(message, LogLevel.ERROR, className)
	def warn(message: String): Unit = logging.log(message, LogLevel.WARN, className)
	def info(message: String): Unit = logging.log(message, LogLevel.INFO, className)
	def fine(message: String): Unit = logging.log(message, LogLevel.FINE, className)

}

case class UILoggingInitResponse(token: String)