package service.tabs_api.model

import common.{JsonEnum, ServiceType}
import common.ServiceType.ServiceType
import io.circe.generic.auto._
import io.circe.{Decoder, HCursor}

sealed trait SubEntityData {
  def dataType: SubEntityDataType.SubEntityDataType
}

object SubEntityData {
  implicit val decoder: Decoder[SubEntityData] = (c: HCursor) =>
    for {
      dType <- c.downField("dataType").as[SubEntityDataType.SubEntityDataType]
      result <- dType match {
        case SubEntityDataType.preview => c.as[Preview]
        case SubEntityDataType.search => c.as[Search]
        case SubEntityDataType.login => c.as[Login]
      }
    } yield result

  case class Preview(
                      sfOrgId: String,
                      objType: String,
                      objId: String,
                      messageId: Option[String],
                      dataType: SubEntityDataType.SubEntityDataType = SubEntityDataType.preview,
                      recordTypeId: Option[String],
                      serviceType: Option[ServiceType]
                    ) extends SubEntityData

  case class Search(
                     args: String,
                     dataType: SubEntityDataType.SubEntityDataType = SubEntityDataType.search
                   ) extends SubEntityData

  case class Login(
                    messageId: Option[String],
                    dataType: SubEntityDataType.SubEntityDataType = SubEntityDataType.login
                  ) extends SubEntityData
}

object SubEntityDataType extends Enumeration with JsonEnum {

  type SubEntityDataType = EnumValue
  type EnumValue = Value

  val preview, search, login = Value
}
