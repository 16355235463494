package service

import com.raquo.laminar.api.L._
import org.scalajs.dom
import service.ServiceManager.UILogging
import service.clipboard_service.{ClipboardService, ClipboardServiceImp}
import service.ui.messages.{Messages, MessagesImp}
import service.ui.spinner.{Spinner, SpinnerImp}
import service.ui_logging.UILoggingService

case object ServiceManager {

  private val spinner = SpinnerImp()
  private val messages = MessagesImp()
  private val clipboard = ClipboardServiceImp()
  private val uILogging = UILoggingService()

  private val node: Div = div(
    spinner.node,
    messages.node,
    clipboard.area,
    uILogging.binders
  )

  def apply(): Div = node

  def Spinner: Spinner = spinner

  def Messages: Messages = messages

  def Clipboard: ClipboardService = clipboard

  def UILogging: UILoggingService = uILogging

//object MouseMovingOps {
//    private val mouseOnTop = Var(false)
//    private val mouseDown = Var(false)
//
//    def $isMouseOnTop: Signal[Boolean] = mouseOnTop.signal
//    def $isMouseDown: Signal[Boolean] = mouseDown.signal
//
//    val detectMouseTopPosition: Observer[Boolean] = Observer[Boolean](onNext = f =>  {
//      println(s"MouseTop: $f")
//      mouseOnTop.set(f)
//    })
//
//    val detectMouseDownPosition: Observer[Boolean] = Observer[Boolean](onNext = f =>  {
//      println(s"Mouse Down: $f")
//      mouseDown.set(f)
//    })
//
//}

}

