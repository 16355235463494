package root_pages.tab_content

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Menu}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.auto_suggest.AutoSuggestComponent
import common.{RepfabricManufacturer, RepfabricUser, ServiceType, TabAccounts, graphic}
import org.scalajs.dom.html
import router.{AuthPage, teamsBotRouter}
import service.tabs_api.TabsApi
import wvlet.log.Logger

case class
RepfabricMappping(accounts: TabAccounts, tabsApi: TabsApi) {

  private val log = Logger.of[RepfabricMappping]

  val reloadBus = new EventBus[Unit]

  val $manufacturers: Signal[List[RepfabricManufacturer]] = tabsApi.rfManufacturers(accounts.secondaryAccount.id).startWith(Nil)

  val manufacturerBus = new EventBus[Option[RepfabricManufacturer]]

  val clientOrgId: String = accounts.secondaryAccount.authOrgId.getOrElse(throw new Exception("Missing repfabric account client org id."))

  val $savedManufacturerId: Signal[Option[String]] = reloadBus.events.flatMap(_ => tabsApi.channelManufacturerId(clientOrgId, accounts.primaryAccount.id)).startWith(None)

  val $channelManufacturer: Signal[Option[RepfabricManufacturer]] = manufacturerBus.events.startWith(None)

  val $user: EventStream[RepfabricUser] = tabsApi.me(accounts.secondaryAccount.id)

  val $isAdmin: Signal[Boolean] = $user.map(_.isAdmin).startWith(false)

  val anyManufacturer = Var(false)

  val showSelector = Var(false)

  val showProfileMenu = Var(false)

  val selectedManufacturer: Var[Option[RepfabricManufacturer]] = Var(None)

  val node: ReactiveHtmlElement[html.Div] = div(


    $savedManufacturerId.combineWith($manufacturers).debugLog() --> Observer[(Option[String], List[RepfabricManufacturer])](onNext = {

      case (None, _) =>
        manufacturerBus.emit(None)
        showSelector.set(true)
      case (Some(str), _) if str == "ANY" =>
        anyManufacturer.set(true)
        manufacturerBus.emit(None)
        showSelector.set(false)
      case (Some(str), manufacturers) =>
        showSelector.set(false)
        manufacturerBus.emit(manufacturers.find(_.id.toString == str))
    }),

    $channelManufacturer --> selectedManufacturer,

    div(
      cls := "tab-header",
      div(
        cls := "slds-grid",
        p("Rf Sync", cls := "title--level-1 slds-m-right--large"),
        p(child.text <-- $user.map(_.`client-name`), cls := "title--level-2")
      ),

      div(
        position := "relative",
        material.IconButton(
          _ => cls := "light profile-icon",
          _.icon := "account_circle",
          _ => onClick.mapTo(!showProfileMenu.now) --> showProfileMenu.writer
        ),
        Menu(
          _ => cls := "profile-menu",
          _.open <-- showProfileMenu,
          _.onClosed.mapTo(false) --> showProfileMenu.writer,
          _.slots.default(
            ListItem(
              _.twoline := true,
              _ => cls := "non-clickable",
              _.slots.default(strong(s"${accounts.primaryAccount.name.getOrElse("")}", cls := "subtitle--level-3")),
              _.slots.secondary(span(s"${accounts.primaryAccount.email.getOrElse("")}"))
            ),
            li(cls := "mdc-list-divider"),

            ListItem(
              _ => cls := "blue",
              _ => graphic := "icon",
              _.slots.graphic(material.Icon(_ => "exit_to_app")),
              _.slots.default(span("Log out", cls := "blue")),
              _ => composeEvents(onClick)(_
                .flatMap(_ => for {
                  _ <- tabsApi.logoutAccount(accounts.secondaryAccount.id)
                  _ <- tabsApi.logout
                } yield ())) --> Observer[Unit](onNext = _ => teamsBotRouter.pushState(AuthPage(Some(ServiceType.office365.value), None)))
            )
          )
        )
      )
    ),

    div(
      cls := "tab-page-wrapper slds-align--absolute-center",
      div(
        cls := "slds-p-horizontal--large",
        p(
          cls := "title--level-3 slds-m-bottom--large",
          child.text <-- $user.map(u => s"Hi, ${u.name.getOrElse("")}!"),
          child.maybe <-- showSelector.signal.map {
            case true => Some(span(" Select a manufacturer account for this channel."))
            case false => None
          }
        ),

        child.maybe <-- showSelector.signal
          .combineWith(anyManufacturer.signal)
          .combineWith($channelManufacturer)
          .map {
            case (true, _, _) => Some(div(
            AutoSuggestComponent[RepfabricManufacturer](
              valueVar = selectedManufacturer,
              $availableValues = $manufacturers,
              label = "Manufacturer",
              charactersNeededForSearch = 0,
              required = true,
              $disabled = anyManufacturer.signal,
              initialValidation = false,
              menuMaxHeight = Some("calc(50vh - 90px)")
            ).node,
            div(
              cls := "slds-grid slds-grid--vertical-align-center negative-m-bottom--small",
              Checkbox(
                _.checked <-- anyManufacturer.signal,
                _.onChange.mapToChecked --> anyManufacturer
              ),
              span("Any manufacturer")
            ),
            div(
              cls := "slds-grid slds-grid--align-end",
              child.maybe <-- $channelManufacturer.map(_.isDefined).map {
                case true => Some(Button(
                  _.label := "Cancel",
                  _ => cls := "slds-m-right--small white",
                  _ => composeEvents(onClick)(_.sample($channelManufacturer)) --> selectedManufacturer,
                  _.disabled <-- $channelManufacturer.combineWith(selectedManufacturer.signal).map(t => t._1 == t._2)
                ))
                case false => None
              },
              Button(
                _.label := "Save",
                _.raised := true,
                _.disabled <-- $savedManufacturerId
                  .combineWith(selectedManufacturer.signal)
                  .combineWith(anyManufacturer.signal)
                  .map { case (savedMan, selectedMan, anyMan) => selectedMan.isEmpty && !anyMan ||
                    savedMan.isDefined && selectedMan.isDefined && savedMan.get == selectedMan.get.id.toString ||
                    savedMan.isDefined && anyMan && savedMan.get == "ANY"
                  },
                _ => composeEvents(onClick)(_
                  .sample(selectedManufacturer.signal)
                  .withCurrentValueOf(anyManufacturer.signal)
                  .flatMap { case (man, anyMan) => for {
                    _ <- tabsApi.updateChannelManufacturerId(
                      clientOrgId,
                      accounts.primaryAccount.id,
                      if (anyMan) "ANY"
                      else man.getOrElse(throw new Exception("Manufacturer is not selected")).id.toString
                    )
                  } yield ()
                  }) --> reloadBus.writer,
              )
            )

          ))
          case (false, anyManufacturer, manufacturer) if anyManufacturer || manufacturer.isDefined => Some(
            p(
              "The sync is configured to migrate activities of ",
              strong(if (anyManufacturer) "all manufacturers" else manufacturer.get.name),
              " to this channel.",

              child.maybe <-- $isAdmin.map {
                case true => Some(p(
                  "Change",
                  cls := "blue clickable slds-m-top--large",
                  onClick.mapTo(true) --> showSelector
                ))
                case false =>
                  None
              }
            )
          )

          case _ => None
          }

      )
    ),
    onMountCallback(_ => reloadBus.emit(()))
  )

}
