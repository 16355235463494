package service.ui.messages

import org.scalajs.dom

import com.raquo.laminar.api.L._
import com.github.uosis.laminar.webcomponents.material

trait Messages {
  def addMessage(mes: String): Unit
}

case class MessagesImp() extends Messages {
  private val messages: Var[List[String]] = Var(List.empty)

  def addMessage(mes: String): Unit = {
    messages.update(list =>
      mes match {
        case mes if !list.contains(mes) => mes :: messages.now
        case _ => list
      }
    )

  }

  private def renderMessage(messageId: Int, initialMessage: String, $message: Signal[String]): Div = {
    val currentValue: Var[Option[String]] = Var(None)
    val closeObserver = Observer[dom.Event](onNext = _ => {
      println(currentValue.now)
      messages.set(messages.now.filterNot(_ == currentValue.now.get))
    })
    div(
      cls := "message-snackbar slds-grid slds-p-around_large slds-m-top_large slds-grid--vertical-align-center",
      $message --> Observer[String](onNext = mes => currentValue.set(Some(mes))),
      p(
        cls := "slds-col slds-size_5-of-6",
        child.text <-- $message,
      ),
      div(
        cls := "slds-col slds-size_1-of-6 slds-align_absolute-center",
        material.Button(
          _.label := "close",
          _ => onClick --> closeObserver
        )
      )

    )
  }

  val node: Div = div(
    cls := "slds-grid slds-grid_vertical messages-container slds-grid--vertical-align-center",
    children <-- messages.signal.split(mes => messages.now.indexOf(mes))(renderMessage)
  )
}
