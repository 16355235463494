package root_pages.sign_in

import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.Select.El
import com.github.uosis.laminar.webcomponents.material.{Button, Select}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ServiceType
import common.ServiceType.{ServiceType, repfabric}
import common.airstream_ops.AirStreamOps
import org.scalajs.dom.html
import root_pages.sign_in.SfLoginType.SfLoginType
import router.{AuthPage, MappingPage, teamsBotRouter}
import service.ServiceManager
import service.tabs_api.{JsVarargsFn, TabsApi, microsoftTeams}
import wvlet.log.Logger

import scala.scalajs.js

case class AuthComponent($page: Signal[AuthPage], tabsApi: TabsApi) {

  private val log = Logger.of[AuthComponent]
  val popupVar = Var(false)

  val authSuccessBus = new EventBus[Unit]
  val $authServiceType: Signal[Option[ServiceType]] = $page.map(_.service).map(stopt => ServiceType.list.find(st => stopt.contains(st.value)))
  val $pageRedirect: Signal[Option[String]] = $page.map(_.redirect)

  val navigationObserver: Observer[Option[String]] = Observer[Option[String]](onNext = {
    case Some(value) if teamsBotRouter.pageForAbsoluteUrl(value).isDefined =>
      teamsBotRouter.pushState(teamsBotRouter.pageForAbsoluteUrl(value).get)
    case None => teamsBotRouter.pushState(MappingPage(None, None))
  })

  val sfLoginType: Var[SfLoginType] = Var(SfLoginType.Production)
  def sfLoginSelector: El = Select(
    _.value <-- sfLoginType.signal.map(_.toString),
    _ =>  SfLoginType.all.map(t => ListItem(
      _.value := t.toString,
      _ => t.toString,
      _.selected <-- sfLoginType.signal.map(_ == t),
      _ => onClick.mapTo(t) --> sfLoginType
    ))
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    authSuccessBus.events
      .sample($authServiceType)
      .flatMap {
        case Some(ServiceType.repfabric) | Some(ServiceType.office365) => ().streamed
        case _ => for {
          uid <- tabsApi.aurinkoUser()
            .map(_.id)
          _ <- tabsApi.refreshAurinkoUser(uid)
        } yield ()

      }
      .sample($pageRedirect) --> navigationObserver,

    cls := "slds-align_absolute-center",//"slds-grid slds-grid_vertical-align-center slds-grid_align-center",
    width := "100vw",
    height := "100vh",
    overflowWrap := "break-word",

    child <-- $page.map(page => {
      val serviceType: Option[ServiceType] = ServiceType.list.find(st => page.service.contains(st.value))

      var authUrl: String = tabsApi.authUrl(
        serviceType,
        Option.when(serviceType.contains(ServiceType.salesforce)) { sfLoginType.now })

      val callbackFunc: JsVarargsFn = { _ => {
        println("successCallback, page redirect is " + page.redirect)
        authSuccessBus.emit(())
        }}

      val failFunc: js.Function1[String, Unit] = { (x: String) =>
        if (x != "CancelledByUser") ServiceManager.Messages.addMessage(x)
      }

       div(
         cls := "slds-p-horizontal--large",
         sfLoginType.signal.changes
           .map(t => tabsApi.authUrl(serviceType, Some(t))) --> Observer[String](authUrl = _),

        p(
          cls := "slds-m-bottom--large",
          if (serviceType.contains(ServiceType.office365)) "Confirm your Microsoft Teams id."
          else s"Log in to ${serviceType.map(_.label).getOrElse("CRM")} to get started."
        ),

         if (serviceType.isEmpty || serviceType.exists(_.isCRM) && !serviceType.contains(ServiceType.repfabric)) {
           p(
             cls := "slds-m-bottom--large",
             ul(
               paddingLeft := "1rem",
               li(
                 cls := "slds-m-bottom--x-small",
                 "Receive workflow signals in channels."
               ),
               li(
                 cls := "slds-m-bottom--x-small",
                 "Access CRM data without leaving Microsoft Teams."
               )
             )
           )
         } else None,

        if (serviceType.contains(ServiceType.salesforce)) {
          div( cls := "slds-m-bottom--large", sfLoginSelector )
        } else None,

        Button(
          _.raised := true,
          _.label := "LOG IN",
          _ => onClick.mapTo(()) --> Observer[Unit](_ => {

            println("Auth url: " + authUrl)

            microsoftTeams.authentication.authenticate(
              js.Dynamic.literal(
                "url" -> authUrl,
                "width" -> 800,
                "height" -> 600,
                "successCallback" -> callbackFunc,
                "failureCallback" -> failFunc//((reason: String) => if (reason != "CancelledByUser") throw new Exception(reason))
              )
            )
          })
        ),


        if (serviceType.isEmpty || serviceType.exists(_.isCRM) && !serviceType.contains(ServiceType.repfabric)) {
          p(
            cls := "slds-m-top--large",
            s"You need to have an active ${serviceType.map(_.label).getOrElse("CRM")} account. ",
            a("Contact us", href := "https://helpdocs.yoxel.com/flow-signals", target := "_blank"),
            " if you have questions."
          )
        } else None
      )
    }
    ),
    //popup
  )
}

object SfLoginType extends Enumeration {
  type SfLoginType = Value

  val
    Production,
    Sandbox,
    Community
    = Value

  val all: List[Value] = Production :: Sandbox :: Nil
}