package common


import com.raquo.laminar.api.L._

package object airstream_ops {

	implicit final class AirStreamOps[T](private val str: T) extends AnyVal {
		def streamed: EventStream[T] = EventStream.fromValue(str)

		def signaled: Signal[T] = Signal.fromValue(str)
	}
}
