package service.exception_handler

import com.raquo.airstream.core.AirstreamError.CombinedError
import common.ServiceType
import org.scalajs.dom
import router.{AuthPage, teamsBotRouter}
import service.ServiceManager
import service.exceptions.{AurinkoApiException, NotAuthorizedException, XhrFailedException}
import service.ui_logging.UILogger
import wvlet.log.Logger

import scala.scalajs.js.JSON

object ExceptionHandler {
  private val uiLogger = UILogger("ExceptionHandler", ServiceManager.UILogging)
  private val log = Logger.of[ExceptionHandler.type]

  def handleAirstreamException(err: Throwable): Unit = {

//    ServiceManager.UILogging.mes.now.foreach(ServiceManager.Messages.addMessage)

    err match {
      case _: NotAuthorizedException =>
        uiLogger.warn("NotAuthorizedException")
        teamsBotRouter.pushState(AuthPage(Some(ServiceType.office365.value), Some(dom.window.location.href)))
      case e: XhrFailedException =>
        uiLogger.error(s"XhrFailedException ${e.toString}")
        ServiceManager.Messages.addMessage(e.toString)
      case e: AurinkoApiException =>
        uiLogger.error(s"AurinkoApiException: ${e.message}")
        ServiceManager.Messages.addMessage(e.message)
      case e: CombinedError => e.causes.collect{case Some(v) => v}.foreach(handleAirstreamException)
      case _ =>
        uiLogger.error(s"Exception: ${err.getMessage}")
        ServiceManager.Messages.addMessage(err.getMessage)
    }


  }

  def handleDomException(err: dom.ErrorEvent): Unit = {
//    ServiceManager.UILogging.mes.now.foreach(ServiceManager.Messages.addMessage)

    uiLogger.error(s"DomException: $err ${err.message} ${err.`type`} ${err.getClass}")
    ServiceManager.Messages.addMessage(s"${err.message}")

  }
}
