package service

package object exceptions {
	case class AurinkoApiException(message: String, code: String) extends Exception("Api exception") {
		override
		def toString = s"$message"
	}

	case class NotAuthorizedException() extends Exception("Not authorized")

	case class XhrFailedException() extends Exception("XhrFailedException") {

		val message: String = "Failed to get server response. Please, try refreshing the page."

		override
		def toString = s"$message"
	}

}
