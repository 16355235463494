package common.ui

import com.github.uosis.laminar.webcomponents.material.{Icon, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.AirStreamOps
import org.scalajs.dom
import org.scalajs.dom.html
import org.scalajs.dom.raw.HTMLElement
import wvlet.log.Logger

import java.awt.TextField

case class SlidePanelComponent(
                                $header: Signal[String] =  "".signaled,
                                collapsedElementsDisplayed: Int,
                                $listOfContent: Signal[List[ReactiveHtmlElement[HTMLElement]]] = List.empty.signaled,
                                $cssClass: Signal[String] = "".signaled,
                                $headerClass: Signal[String] = "slide-panel-header".signaled,
                                noEntriesMessage: Boolean = false
                              ) {
  private val log = Logger.of[SlidePanelComponent]
  val clickBus = new EventBus[dom.MouseEvent]
  val isExpanded: Var[Boolean] = Var(false)

  def collapsedHeight: Signal[String] = {
    $listOfContent.map{
      case l if l.length >
        collapsedElementsDisplayed =>
        log.info(s"collapsedHeight ${ l.slice(0, collapsedElementsDisplayed).map(_.ref.offsetHeight).sum + "px"}")

        l.slice(0, collapsedElementsDisplayed).map(_.ref.offsetHeight).sum + "px"
      case _ => "auto"
    }
  }

  def expandedHeight = {
    log.info("expandedHeight")
    $listOfContent.map(_.map(_.ref.offsetHeight).sum + "px")
  }


  val node: Div = div(
    cls := s"slide-panel",
    cls <-- $cssClass,

    child.maybe <-- $header.map{
      case str if str.nonEmpty => Some(p( cls <-- $headerClass, str ))
      case _ => None
    },

    div(
      cls := "slide-panel-content",

      children <-- $listOfContent,

      height := "auto",

      $listOfContent --> Observer[List[ReactiveHtmlElement[HTMLElement]]]
        (onNext = list => list.drop(collapsedElementsDisplayed).foreach(_.ref.style.display = "none")),

//      inContext(th =>
//        isExpanded.signal.changes.filter(_ == true)
//          .sample(collapsedHeight)
//          .withCurrentValueOf(expandedHeight)
//            --> Observer[(String, String)](onNext = { case (col, exp) =>
//            th.ref.style.height = col
//            th.ref.style.height = exp
//          }),
//
//      ),


      inContext(th =>
        isExpanded.signal.changes.filter(_ == false)
          .sample($listOfContent)
          --> Observer[List[ReactiveHtmlElement[HTMLElement]]](onNext = list => {

          th.ref.style.height = th.ref.offsetHeight + "px"
          th.ref.style.height = list.take(collapsedElementsDisplayed).map(_.ref.offsetHeight).sum + "px"

          list.drop(collapsedElementsDisplayed).foreach(_.ref.style.display = "none")

//          th.ref.style.height = th.ref.offsetHeight + "px"
        }),
      ),

      inContext(th =>
        isExpanded.signal.changes.filter(_ == true)
          .sample($listOfContent)
          --> Observer[List[ReactiveHtmlElement[HTMLElement]]](onNext = list => {

          th.ref.style.height = th.ref.offsetHeight + "px"

          log.info(th.ref.offsetHeight + "px")
          list.foreach(_.ref.style.display = "")


        }),
      ),

  inContext(th =>
          isExpanded.signal.changes.filter(_ == true)
            .delay()
            .sample($listOfContent)
            --> Observer[List[ReactiveHtmlElement[HTMLElement]]](onNext = list => {

            log.info(th.ref.scrollHeight + "px")
            th.ref.style.height = th.ref.scrollHeight + "px"

          }),
        ),

      inContext(th =>
        isExpanded.signal.changes.delay(400) --> Observer[Boolean](onNext = _ => {
          th.ref.style.height = "auto"
        })
      ),

    ),

    child.maybe <-- $listOfContent.map{
      case l if l.isEmpty => Some(p("No entries", cls := "simple-card"))
      case _ => None
    },

    child.maybe <-- $listOfContent.map(list => if (list.length > collapsedElementsDisplayed)
      Some(div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid_align-center clickable",
        Icon(
          _ => cls := "expand-icon",
          _ => cls <-- isExpanded.signal.map(if (_) "icon-up" else "icon-down"),
          _ => "keyboard_arrow_left",
        ),
        onClick.mapTo(()) --> Observer[Unit](onNext = _ =>
          if (isExpanded.now) isExpanded.set(false)
          else isExpanded.set(true)
        )
      ))
    else
      Some(div(cls := ""))),




  )
}
