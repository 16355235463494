package root_pages.tab_content

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ServiceType.ServiceType
import common.{AurinkoAccount, ServiceType, TabAccounts}
import common.TabCompanyOps
import common.airstream_ops.AirStreamOps
import org.scalajs.dom.html
import router.{AuthPage, MappingPage, teamsBotRouter}
import service.ServiceManager
import service.tabs_api.TabCompany.TabCompany
import service.tabs_api.{TabCompany, TabsApi}
import service.ui_logging.UILogger
import wvlet.log.Logger

case class TabContent($page: Signal[MappingPage], tabsApi: TabsApi) {

  private val log = Logger.of[TabContent]
  private val uiLogger = UILogger("TabContent", ServiceManager.UILogging)

  uiLogger.info("TabContent initialized")

  val needAuthBus: EventBus[Option[ServiceType]] = new EventBus[Option[ServiceType]]
  val navigateToAuth: Observer[Option[ServiceType]] = Observer[Option[ServiceType]](t =>
    teamsBotRouter.pushState(AuthPage(t.map(_.value))))
  val logoutAccountsBus: EventBus[(List[AurinkoAccount], Boolean)] = new EventBus[(List[AurinkoAccount], Boolean)]

  val accountsBus = new EventBus[TabAccounts]
  val $accounts: EventStream[TabAccounts] = accountsBus.events

  //
  //  def accountAuthOrgId(acc: AurinkoAccount): String = {
  //    acc.authOrgId.getOrElse(throw new Exception(s"Missing authOrgId: ${acc.serviceType.value} account (${acc.id})"))
  //  }

  val setPrivateChannelIdBus = new EventBus[TabAccounts]


  val node: ReactiveHtmlElement[html.Div] = div(

    $accounts --> Observer[TabAccounts]
      (accs => {
        uiLogger.info(s"accounts: ${accs.primaryAccount.email} ${accs.secondaryAccount.email}")
        ServiceManager.UILogging.getLevelAndInit(accs.primaryAccount.id, tabsApi.auClientId)
      }),

    needAuthBus.events --> navigateToAuth,

    logoutAccountsBus.events
      .flatMap{case (list, withNavigation) => for {
        _ <- EventStream.combineSeq(
          list.map(_.id).map(tabsApi.logoutAccount))

        navigate = withNavigation
      } yield navigate}
      .filter(_ == true)
      .mapTo(tabsApi.crmType) --> navigateToAuth,

    tabsApi.$tabOwner.changes.collect{case Some(v) => v} --> Observer[TabCompany](c => ServiceManager.UILogging.setApiUrl(c.toServiceType)),

    $page
      .map(route => {
        if (tabsApi.needClientId) tabsApi.setClientId(route.clientId.getOrElse(throw new Exception("Missing au client id")))
        if (tabsApi.needToDefineTabOwner) {
          tabsApi.setTabCompany(route.svc.getOrElse("Repfabric"))

        }

        uiLogger.info(s"Au clientId: ${route.clientId}")
        uiLogger.info(s"Tab crmType: ${route.svc}")
      })
      .flatMap(_ => if (tabsApi.missingMsContext) tabsApi.setMsContextIds() else ().streamed)
      .flatMap(_ => tabsApi.accounts) --> Observer[List[AurinkoAccount]](onNext = list => {

      val primaryList = list.filter(ac => ac.userAccountType == "primary" && ac.tokenStatus.ok)
      if (primaryList.isEmpty) needAuthBus.emit(Some(ServiceType.office365))

      else {
        val currentCrmList = list.filter(ac =>
          ac.userAccountType == "secondary" && ac.tokenStatus.ok && tabsApi.crmType.forall(_ == ac.serviceType) && ac.serviceType.isCRM
        )

        val otherCrmList = list.filter(ac => ac.serviceType.isCRM && !currentCrmList.contains(ac))

        if (otherCrmList.nonEmpty) {
          logoutAccountsBus.emit(otherCrmList, false)
        }

        if (currentCrmList.isEmpty) {
          needAuthBus.emit(tabsApi.crmType)
        } else if (currentCrmList.length > 1) {
          logoutAccountsBus.emit(currentCrmList, true)
        } else {
          if (tabsApi.isPrivateChannel && tabsApi.msChannelId.nonEmpty) {
            setPrivateChannelIdBus.emit(TabAccounts(primaryList.head, currentCrmList.head))
          } else {
            accountsBus.emit(TabAccounts(primaryList.head, currentCrmList.head))
          }
        }
      }
    }),

    setPrivateChannelIdBus.events
      .flatMap { accs =>
        for {
          savedId <- tabsApi.getGroupIdFromStorage(accs.primaryAccount.id)

          userTeams <-
            if (savedId.isEmpty) tabsApi.getUserTeams(accs.primaryAccount.id).map(Some(_))
            else None.streamed

          teamId <- if (savedId.isEmpty) {
            EventStream.combineSeq(userTeams.getOrElse(Nil)
              .map { team =>
                tabsApi
                  .getTeamPrivateChannels(team.id, accs.primaryAccount.id)
                  .map { list =>
                    log.info(s"Channels: $list")
                    team.id -> list
                  }
              })
              .map(t => Some(t
                .find(_._2.exists(_.id == tabsApi.channelId))
                .getOrElse(throw new Exception("Failed to get team id"))
                ._1)
              )
            //            .flatMap { case (teamId, list) =>
            //              EventStream.fromSeq(list)
            //                .map(i => {
            //                  log.info(s"teamId i $teamId: ${i.id}")
            //                  teamId -> i
            //                })
            //            }
            //            .filter(t => {
            //              log.info(s"This is filter ${t._2}")
            //              t._2.exists(_.id == tabsApi.channelId)
            ////            })
            //            .map(_._1)
            //            .map(c => Some(c))
          } else None.streamed
          _ <- if (teamId.isDefined) {
            log.info(s"teamId $teamId")
            tabsApi.saveChannelTeamId(accs.primaryAccount.id, teamId.get)
          } else {
            log.info(s"teamId None")
            ().streamed
          }
          result <- (
              if (savedId.isDefined) savedId.get
              else teamId.getOrElse(throw new Exception("Failed to get private channel id"))
            ).streamed
        } yield (result, accs)

      } --> Observer[(String, TabAccounts)](onNext = {
      case (id, accs) =>
        tabsApi.msGroupId = Some(id)
        accountsBus.emit(accs)
    }),

    child <-- $accounts.map {

      case accounts if tabsApi.tabCompany == TabCompany.Yoxel =>
        uiLogger.info("salesforce content")
        CrmContent(accounts, tabsApi).node
      case accounts =>
        uiLogger.info("rf content")
        RepfabricMappping(accounts, tabsApi).node
    }


  )
}
