import com.raquo.laminar.api.L._
import com.raquo.waypoint._
import common.CirceStringOps
import io.circe.generic.auto.exportEncoder
import io.circe.generic.codec.DerivedAsObjectCodec.deriveCodec
import io.circe.syntax._
import root_pages.tab_content.TabContent
import root_pages.removal.RemovalComponent
import root_pages.sign_in.AuthComponent
import root_pages.subentity.SubentityComponent
import service.tabs_api.TabsApi

package object router {

  val authRoute: Route[AuthPage, (Option[String], Option[String])] = Route.onlyQuery[AuthPage, (Option[String], Option[String])](
    encode = p => (p.service, p.redirect),
    decode = args => AuthPage(args._1, args._2),
    pattern = (root / "login" / endOfSegments) ? (param[String]("service").? & param[String]("redirect").?),
    basePath = Router.localFragmentBasePath
  )

  val mappingRoute: Route[MappingPage, (Option[String], Option[String])] = Route.onlyQuery[MappingPage, (Option[String], Option[String])](
    encode = p => (p.clientId, p.svc),
    decode = arg => MappingPage(arg._1, arg._2),
    (root / "mapping" / endOfSegments) ? (param[String]("clientId").? & param[String]("svc").?),
    basePath = Router.localFragmentBasePath
  )

  val removalRoute: Route[RemovalPage, (Option[String], Option[String])] = Route.onlyQuery[RemovalPage, (Option[String], Option[String])](
    encode = p => (p.clientId, p.svc),
    decode = arg => RemovalPage(arg._1, arg._2),
    (root / "remove" / endOfSegments) ? (param[String]("clientId").? & param[String]("svc").?),
    basePath = Router.localFragmentBasePath
  )


  val teamsBotRouter = new Router[Page](
    routes = List(authRoute, mappingRoute, removalRoute),

    getPageTitle = _.toString, // mock page title (displayed in the browser tab next to favicon)
    serializePage = page => page.asJson.toString, // serialize page data for storage in History API log
    deserializePage = pageStr => pageStr.decodeAs[Page],//todo need to have a method for decoding // deserialize the above
//    routeFallback = _ => MappingPage(None, None)
  )(
    $popStateEvent = windowEvents.onPopState, // this is how Waypoint avoids an explicit dependency on Laminar
    owner = unsafeWindowOwner, // this router will live as long as the window
  )

  def pageSplitter(tabsApi: TabsApi): SplitRender[Page, HtmlElement] = SplitRender[Page, HtmlElement](teamsBotRouter.$currentPage)
    .collectSignal[AuthPage] { $page => AuthComponent($page, tabsApi).node }
    .collectSignal[MappingPage] { $page => TabContent($page, tabsApi).node }
    .collectSignal[RemovalPage] { $page => RemovalComponent($page, tabsApi).node }
}

