package service.http

import com.raquo.airstream.core.EventStream
import com.raquo.airstream.custom.{CustomSource, CustomStreamSource}
import com.raquo.airstream.web.AjaxEventStream
import org.scalajs.dom
import service.ui.spinner.SpinnerCaller
import com.raquo.laminar.api.L._
import common.airstream_ops.AirStreamOps
import common.{Calendar, CirceStringOps, PagedApiResponse}
import io.circe.Json
import io.circe.generic.auto.exportDecoder
import org.scalajs.dom.ext.Ajax
import service.ServiceManager
import wvlet.log.Logger

import scala.scalajs.js.URIUtils
import scala.util.{Failure, Success}

case class ApiRequester(apiUrl: String, validation: dom.XMLHttpRequest => String) {

  val standardHeaders: Map[String, String] = Map("Content-Type" -> "application/json")
 // val extraHeaders = Map("X-Aurinko-AccountId" -> "36", "X-Aurinko-ClientId" ->"24")

  def customStream(spinnerCaller: Option[SpinnerCaller]): EventStream[Unit] = CustomStreamSource[Unit]((fireValue, _, _, _) => {

    CustomSource.Config(
      onStart = () => {

        if (spinnerCaller.isDefined) {
          ServiceManager.Spinner.show(spinnerCaller.get)
        }

        fireValue(())
      },
      onStop = () => {
        if (spinnerCaller.isDefined) {
          ServiceManager.Spinner.hide(spinnerCaller.get)
        }

      }
    )
  })

  private val log = Logger.of[ApiRequester.type]

  def get(path: String,
          queryParams: Map[String, Option[String]] = Map.empty,
          headers: Map[String, String] = Map.empty,
          showSpinner: Boolean = true): EventStream[String] = {
    log.info(s"Get $apiUrl$path ")
    val spinnerCaller = Option.when(showSpinner) { new SpinnerCaller }

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.get(
        s"$apiUrl$path${toQueryString(queryParams)}",
        headers = standardHeaders ++ headers,
        withCredentials = true
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            ServiceManager.Spinner.hide(spinnerCaller.get)
          }
          validation(resp)
        }
      )

  }

//todo: universal type
  def repeatGet(path: String, queryParams: Map[String, Option[String]] = Map.empty, headers: Map[String, String] = Map.empty): EventStream[List[Calendar]] =
    get(path, queryParams, headers).map(_.decodeAs[PagedApiResponse[Calendar]]).flatMap{
      case r if r.pageToken.getOrElse("").isEmpty => r.records.streamed
      case r => repeatGet(path, queryParams ++ Map("pageToken" -> r.pageToken), headers).map(r.records ++ _)
    }

  def post(path: String,
           body: Option[Json] = None,
           headers: Map[String, String] = Map.empty,
           showSpinner: Boolean = true): EventStream[String] = {

    val spinnerCaller = Option.when(showSpinner) { new SpinnerCaller }

    customStream(spinnerCaller).flatMap(_ => AjaxEventStream.post(s"$apiUrl$path", data = body match {
      case None => ""
      case Some(b) => Ajax.InputData.str2ajax(b.toString)
    },
      headers = standardHeaders ++ headers,
      withCredentials = true)
      .completeEvents
      .map{resp =>
//        log.info(s"map resp ${resp.responseText}")
        if (spinnerCaller.isDefined) {
          ServiceManager.Spinner.hide(spinnerCaller.get)
        }
        validation(resp)
      })
  }

  def put(path: String,
          body: Option[Json] = None,
          headers: Map[String, String] = Map.empty,
          showSpinner: Boolean = true): EventStream[String] = {

    val spinnerCaller = Option.when(showSpinner) { new SpinnerCaller }

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.put(s"$apiUrl$path", data = body match {
        case None => ""
        case Some(b) => Ajax.InputData.str2ajax(b.toString)
      },
        headers = standardHeaders ++ headers,
        withCredentials = true
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            ServiceManager.Spinner.hide(spinnerCaller.get)
          }
          validation(resp)
        })
  }

  def patch(path: String,
            body: Option[Json] = None,
            headers: Map[String, String] = Map.empty,
            showSpinner: Boolean = true): EventStream[String] = {

    val spinnerCaller = Option.when(showSpinner) { new SpinnerCaller }

    customStream(spinnerCaller).flatMap(_ => AjaxEventStream.patch(s"$apiUrl$path", data = body match {
      case None => ""
      case Some(b) => Ajax.InputData.str2ajax(b.toString)
    },
      headers = standardHeaders ++ headers,
      withCredentials = true)
      .completeEvents
      .map{resp =>
//        log.info(s"map resp ${resp.responseText}")
        if (spinnerCaller.isDefined) {
          ServiceManager.Spinner.hide(spinnerCaller.get)
        }
        validation(resp)
      })
  }

  def delete(path: String,
             queryParams: Map[String, Option[String]] = Map.empty,
             headers: Map[String, String] = Map.empty,
             showSpinner: Boolean = true): EventStream[String] = {
    log.info(s"delete $apiUrl$path ")

    val spinnerCaller = Option.when(showSpinner) { new SpinnerCaller }

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.delete(
        s"$apiUrl$path${toQueryString(queryParams)}",
        headers = standardHeaders ++ headers,

        withCredentials = true
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            ServiceManager.Spinner.hide(spinnerCaller.get)
          }
          validation(resp)
        }
      )

  }

  def toQueryString(params: Map[String, Option[String]]): String = {
    val str = (for {
      (k, v) <- params
      if v.getOrElse("").nonEmpty
    }
    yield {
      s"$k=${URIUtils.encodeURIComponent(v.get)}"
    }).mkString("&")

    str match {
      case s if s.nonEmpty => s"?$s"
      case _ => ""
    }
  }
}
