import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import io.circe.{Decoder, parser}
import org.scalajs.dom
import router.{Page, teamsBotRouter}
import com.raquo.laminar.api.L._
import common.ServiceType.ServiceType
import common.airstream_ops.AirStreamOps
import org.scalajs.dom.html
import service.tabs_api.TabCompany.TabCompany
import wvlet.log.Logger

import java.net.URLDecoder
import java.time.format.DateTimeFormatter
import scala.scalajs.js

package object common {
  implicit final class CirceStringOps(private val str: String) extends AnyVal {
    //    private val log = Logger.of[CirceStringOps]
    def decodeAs[A: Decoder]: A = parser
      .decode[A](str)
      .fold(
        throw _,
        v => v
      )

    def safelyDecodeAs[A: Decoder]: Option[A] = parser
      .decode[A](str)
      .fold(
        _ => {
          println("Invalid json")
          None
        },
        v => Some(v)
      )
  }

  implicit final class TabCompanyOps(private val company: TabCompany) extends AnyVal {
    def toServiceType: Option[ServiceType] = ServiceType.list.find(_.value == company.toString)
  }

  def navigateOnClick(route: Page): Observer[dom.MouseEvent] = Observer[dom.MouseEvent](onNext = _ => teamsBotRouter.pushState(route))

  def findQueryParam(name: String): Option[String] = {
    dom.window.location.search
      .split("\\?")
      .last
      .split("&")
      .find(_.startsWith(s"$name="))
      .collect {
        case x: String if x.split(s"$name=").length > 1 =>
          URLDecoder.decode(x.split(s"$name=").last, "UTF-8")//??
      }
  }

  val graphic: Prop[String] = customProp("graphic", StringAsIsCodec)

  implicit final class MountContextOpps(private val context: MountContext[HtmlElement]) {

    private val log = Logger.of[MountContextOpps]

    def findElementInShadowRoot(cssSelector: String, node: dom.html.Element = context.thisNode.ref, withDelay: Boolean): EventStream[Option[dom.html.Element]] = {
      val done: Var[Boolean] = Var(false)
      val elemBus = new EventBus[Option[dom.html.Element]]

      done.signal.flatMap(s =>
        if (!s) {
          EventStream.periodic(300, resetOnStop = true, emitInitial = !withDelay).mapTo(

            node.asInstanceOf[js.Dynamic].shadowRoot.querySelector(cssSelector)
          )
        } else {
          EventStream.empty
        }
      ).foreach(elem => {
        if (elem == null) {
          //          log.info("elem null")
        } else {
          done.set(true)
          elemBus.emit(Some(elem.asInstanceOf[dom.html.Element]))
        }
      })(context.owner)

      //stop if element not found in 3 seconds
      ().streamed.delay(3000).filter(_ => !done.now).foreach(_ => {
        log.warn(s"Elem $cssSelector not found in shadow root")
        done.set(true)
        elemBus.emit(None)
      })(context.owner)

      elemBus.events

    }

    def addStyleToShadowElement(cssSelector: String, property: String, value: String, withDelay: Boolean = false): Unit = {

      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          //          log.info(s"set $value to $property of $cssSelector")
          elem.style.setProperty(property, value)
        }
        case None => ()
      }(context.owner)
    }

    def addClassToShadowElement(cssSelector: String, className: String, withDelay: Boolean = false): Unit = {
      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => elem.classList.add(className)
        case None => ()
      }(context.owner)
    }

    def findNestedShadowElement(cssSelector1: String, cssSelector2: String, withDelay: Boolean): EventStream[Option[html.Element]] = {
      val elemBus = new EventBus[Option[dom.html.Element]]

      findElementInShadowRoot(cssSelector1, withDelay = withDelay).foreach {
        case Some(elem) => findElementInShadowRoot(cssSelector2, elem, withDelay = withDelay).foreach {
          case Some(nestedEl) =>
            //            log.info("EMIT NESTED ELEM")
            elemBus.emit(Some(nestedEl))
          case None => ()
        }(context.owner)
        case None => ()
      }(context.owner)

      elemBus.events
    }

    def addStyleToNestedShadowElement(cssSelector: String, cssSelector2: String, property: String, value: String, withDelay: Boolean = false): Unit = {

      findNestedShadowElement(cssSelector, cssSelector2, withDelay = withDelay).foreach {
        case Some(elem) => elem.style.setProperty(property, value)
        case None => ()
      }(context.owner)
    }
//
//    def addAttrToNestedShadowElement(cssSelector: String, cssSelector2: String, attr: String, value: String, withDelay: Boolean = false): Unit = {
//
//      findNestedShadowElement(cssSelector, cssSelector2, withDelay = withDelay).foreach {
//        case Some(elem) => elem.setAttribute(attr, value)
//        case None => ()
//      }(context.owner)
//    }



  }


  //if T is not dom.MouseEvent effect must be set
  case class OnClickOptions[T](
                              effect: Option[EventStream[T]],
                              observer: Observer[T]
                              )

  implicit final class StringOps(private val str: String) extends AnyVal {
    def containsIgnoreCase(anotherStr: String): Boolean = str.toLowerCase.contains(anotherStr.toLowerCase)

  }

  object CssSize extends Enumeration {
    type CssSize = Value

    val `xx-small`, `x-small`, small, medium, large, `x-large`, `xx-large` = Value
  }

  val dateTimeFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("MMM dd, yyyy \'at\' h:mm a")
}
