import com.github.uosis.laminar.webcomponents.material.Button
import com.raquo.laminar.api.L._
import common.ServiceType
import org.scalajs.dom
import router.{AuthPage, pageSplitter, teamsBotRouter}
import service.ServiceManager
import service.exception_handler.ExceptionHandler
import service.origins.Origins
import service.tabs_api.{TabCompany, TabsApi}
import service.ui.messages.Messages
import service.ui.spinner.Spinner

object Root extends App {

  AirstreamError.registerUnhandledErrorCallback(ExceptionHandler.handleAirstreamException)

  val tabsApi = TabsApi(Origins.apiUrl)

  renderOnDomContentLoaded(dom.document.getElementById("app-container"),
    div(
      windowEvents.onError --> Observer[dom.ErrorEvent](onNext = err => ExceptionHandler.handleDomException(err)),

      cls.toggle("rf-content", "") <-- tabsApi.$tabOwner.map(_.contains(TabCompany.Repfabric)),

      minWidth := "100%",
      minHeight := "100%",

      ServiceManager(),

      child <-- pageSplitter(tabsApi).$view,

//      children <-- ServiceManager.UILogging.mes.signal.map(_.map(d => div(color := "white", d)))

//      windowEvents.onMouseMove.map(_.pageY <= 10) --> ServiceManager.MouseMovingOps.detectMouseTopPosition,
//      windowEvents.onMouseMove.map(_.pageY > 35) --> ServiceManager.MouseMovingOps.detectMouseDownPosition,
    )
  )
}

